<template>
  <div style="text-align: left">
    <md-tabs>
      <md-tab class="tab-padding figtree-normal-white-14px" id="tab-home" md-label="Privacy Policy">
        <p class="hdr figtree-bold-white-16px" style="font-w">Privacy Policy</p><br/>
        <p>Last modified: September 15, 2020</p>
        <br/>
        <p>Introduction</p>
        <br/>
        <p>Aries Adaptive Media, LLC, a North Carolina limited liability company d/b/a Reflex Radio
          (<strong>"Company"</strong> or<strong> "We"</strong>) respect your privacy and are committed to protecting it
          through our compliance with this policy.</p>
        <br/>
        <p>This policy describes the types of information we may collect from you or that you may provide when you visit
          the website www.reflex-radio.com (our "Website"), or when you install, access or use any Reflex Radio Mobile
          Application (the &ldquo;<strong>App</strong>&rdquo;) and our practices for collecting, using, maintaining,
          protecting and disclosing that information.</p>
        <br/>
        <p>This policy applies to information we collect:</p>
        <br/>
        <ul>
          <li>On this Website.</li>
          <li>Via the App.</li>
          <li>In e-mail, text and other electronic messages between you and this Website and/or the App.</li>
          <li>Through any mobile and desktop applications you download from this Website, which provide dedicated
            non-browser-based interaction between you and this Website.
          </li>
          <li>When you interact with our advertising and applications on third-party websites and services, if those
            applications or advertising include links to this policy.
          </li>
        </ul>
        <br/>
        <p>It does not apply to information collected by:</p>
        <br/>
        <ul>
          <li>us offline or through any other means, including on any other website operated by Company or any third
            party (including our affiliates and subsidiaries); or
          </li>
          <li>any third party (including our affiliates and subsidiaries), including through any application or content
            (including advertising) that may link to or be accessible from or on the Website or App.
          </li>
        </ul>
        <br/>
        <p>Please read this policy carefully to understand our policies and practices regarding your information and how
          we will treat it. If you do not agree with our policies and practices, your choice is not to use the App or
          our Website. By accessing or using the App and/or this Website, you agree to this privacy policy. This policy
          may change from time to time. Your continued use of the App and/or this Website after we make changes is
          deemed to be acceptance of those changes, so please check the policy periodically for updates.</p>
        <br/>
        <p>Persons Under the Age of 13</p>
        <br/>
        <p>The App and our Website is not intended for persons under 13 years of age. No one under age 13 may provide
          any information to or on the Website. We do not knowingly collect or solicit personal information from
          children under 13. If you are under 13, do not use or provide any information on or through the App, this
          Website or on or through any of its features/register on or through the App or the Website, make any purchases
          through the App or the Website, use any of the interactive or public comment features of the App or this
          Website or provide any information about yourself to us, including your name, address, telephone number,
          e-mail address or any screen name or user name you may use. If we learn we have collected or received personal
          information from a child under 13 without verification of parental consent, we will take all commercially
          reasonable measures to delete that information. If you believe we might have any information from or about a
          child under 13, please contact us at <a
              href="mailto:contactus@reflex-radio.com">contactus@reflex-radio.com</a>.</p>
        <br/>
        <p>Information We Collect About You and How We Collect It</p>
        <br/>
        <p>We collect several types of information from and about users of our App and Website, including
          information:</p>
        <br/>
        <ul>
          <li>by which you or third parties such as attendees to our events may be personally identified, such as name,
            age, postal address, e-mail address, telephone number, or any other identifier by which you may be contacted
            online or offline ("personal information");
          </li>
          <li>financial information required to process any purchases on the Website or through the App, such credit
            card information;
          </li>
          <li>that is about you but individually does not identify you;</li>
          <li>that is used to register with the App using your existing social media account details, such as Facebook,
            Twitter or other social media account (such social media login information to be collected as set forth
            herein); and/or
          </li>
          <li>about your internet connection, the equipment you use to access our Website and usage details.</li>
        </ul>
        <br/>
        <p>We collect this information:</p>
        <br/>
        <ul>
          <li>Directly from you when you provide it to us.</li>
          <li>Automatically as you download the App, navigate through the site or App. Information collected
            automatically may include usage details, IP addresses and information collected through cookies and other
            tracking technologies.
          </li>
          <li>With respect to social media logins, from your social media provider. The profile information we receive
            may vary depending on the social media provider concerned, but will often include your name, email address,
            contacts list, profile picture as well as other information you choose to make public on such social media
            platform. This information will only be used for the purposes that are described in this privacy policy.
          </li>
          <li>From third parties, for example, our business partners.</li>
        </ul>
        <br/>
        <p><strong><u>Information You Provide to Us</u></strong><strong>.</strong> The information we collect on or
          through our App or Website may include:</p>
        <br/>
        <ul>
          <li>Information that you provide by filling in forms in the App or on our Website. This includes information
            provided at the time of registering to use the app or our Website, posting material, subscribing to our
            service, or requesting further services. We may also ask you for information when you enter a contest or
            promotion sponsored by us and when you report a problem with the App or our Website.
          </li>
          <li>Records and copies of your correspondence (including e-mail addresses and phone numbers), if you contact
            us.
          </li>
          <li>Your responses to surveys that we might ask you to complete for research purposes.</li>
          <li>Details of transactions you carry out through the App or Website and of the fulfillment of your orders.
            You may be required to provide financial information before placing an order through the App or Website.
          </li>
          <li>Your search queries on the App or Website.</li>
        </ul>
        <br/>
        <p>We do not store credit card numbers, security codes or identifiable credit card data but pass that data
          through PCI compliant gateways at the time of charging. We may use this information for billing purposes, and
          to share this information with authorized, event-related third parties.</p>
        <br/>
        <p>You also may provide information to be published or displayed (hereinafter, "posted") on public areas of the
          Website, or transmitted to other users of the Website or third parties (collectively, "User Contributions").
          Your User Contributions are posted on and transmitted to others at your own risk. Although we limit access to
          certain pages, please be aware that no security measures are perfect or impenetrable. Additionally, we cannot
          control the actions of other users of the Website with whom you may choose to share your User Contributions.
          Therefore, we cannot and do not guarantee that your User Contributions will not be viewed by unauthorized
          persons.</p>
        <br/>
        <p><strong><u>Usage Details, IP Addresses And Cookies/, Cookies and Other Technologies</u></strong></p>
        <br/>
        <p>As you navigate through and interact with our Website or when you download, access and use the App, we may
          automatically collect certain information about your equipment, browsing actions and patterns, including:</p>
        <br/>
        <ul>
          <li>Details of your visits to and use of our Website and App, including traffic data, location data, logs and
            other communication data and the resources that you access and use on or through the App or the Website.
          </li>
          <li>Information about your computer and internet connection, including your IP address, operating system and
            browser type.
          </li>
          <li>Information about your mobile device and internet connection, including the device's unique device
            identifier, IP address, operating system, browser type, mobile network information and the device's
            telephone number.
          </li>
          <li>The App also may access metadata and other information associated with other files stored on your
            device.
          </li>
          <li>This App may collect real-time information about the location of your device.</li>
        </ul>
        <br/>
        <p>If you do not want us to collect this information do not use the Website and do not download the App or
          delete it from your device.</p>
        <br/>
        <p>We also may use these technologies to collect information about your activities over time and across
          third-party websites, apps or other online services (behavioral tracking). Email <a
              href="mailto:contactus@reflex-radio.com">contactus@reflex-radio.com</a> for information on how you can opt
          out of behavioral tracking on or through this App and how we respond to browser signals and other mechanisms
          that enable consumers to exercise choice about behavioral tracking.</p>
        <br/>
        <p>The information we collect automatically is statistical data. It helps us to improve our Website and App and
          to deliver a better and more personalized service by enabling us to:</p>
        <br/>
        <ul>
          <li>Estimate our audience size and usage patterns.</li>
          <li>Store information about your preferences, allowing us to customize our App and Website according to your
            individual interests.
          </li>
          <li>Speed up your searches.</li>
          <li>Recognize you when you return to our App and Website.</li>
        </ul>
        <br/>
        <p>The technologies we use for this automatic data collection may include:</p>
        <br/>
        <ul>
          <li><strong>Cookies (or browser cookies).</strong> A cookie is a small file placed on the hard drive of your
            computer. You may refuse to accept browser cookies by activating the appropriate setting on your browser.
            However, if you select this setting you may be unable to access certain parts of our Website. Unless you
            have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you
            direct your browser to our Website.
          </li>
          <li><strong>Mobile Cookies</strong>. A mobile cookie is a small file placed on your smartphone. It may be
            possible to refuse to accept mobile cookies by activating the appropriate setting on your smartphone.
            However, if you select this setting you may be unable to access certain parts of our App.
          </li>
          <li><strong>Flash Cookies.</strong> Certain features of our Website may use local stored objects (or Flash
            cookies) to collect and store information about your preferences and navigation to, from and on our Website.
            Flash cookies are not managed by the same browser settings as are used for browser cookies.
          </li>
          <li><strong>Web Beacons.</strong> Pages of the Website, App and our e-mails may contain small electronic files
            known as web beacons (also referred to as clear gifs, pixel tags and single-pixel gifs) that permit the
            Company, for example, to count users who have visited those pages or opened an e-mail and for other related
            app statistics (for example, recording the popularity of certain app content and verifying system and server
            integrity).
          </li>
        </ul>
        <br/>
        <p>Third-Party Information Collection</p>
        <br/>
        <p>&nbsp;</p>
        <br/>
        <p>When you use the Website or App or their content, certain third parties may use automatic information
          collection technologies to collect information about you or your device. These third parties may include:</p>
        <br/>
        <ul>
          <li>Billing processor.</li>
          <li>Advertisers, ad networks and ad servers.</li>
          <li>Analytics companies.</li>
          <li>Your mobile device manufacturer.</li>
          <li>Your mobile service provider.</li>
        </ul>
        <br/>
        <p>&nbsp;</p>
        <br/>
        <p>These third parties may use tracking technologies to collect information about you when you use the App or
          Website. The information they collect may be associated with your personal information or they may collect
          information, including personal information, about your online activities over time and across different
          websites, apps and other online services websites. They may use this information to provide you with
          interest-based (behavioral) advertising or other targeted content. We do not control these third parties'
          tracking technologies or how they may be used. If you have any questions about an advertisement or other
          targeted content, you should contact the responsible provider directly.</p>
        <br/>
        <p>&nbsp;</p>
        <br/>
        <p>How We Use Your Information</p>
        <br/>
        <p>We use information that we collect about you or that you provide to us, including any personal
          information:</p>
        <br/>
        <ul>
          <li>To provide you with the App and its contents.</li>
          <li>To present our Website and its contents to you.</li>
          <li>To give you notices about your account.</li>
          <li>To notify you when App updates are available, and of changes to any products or services we offer or
            provide through it.
          </li>
          <li>To provide you with information, products or services that you request from us.</li>
          <li>To carry out our obligations and enforce our rights arising from any contracts entered into between you
            and us, including for billing and collection.
          </li>
          <li>To notify you about changes to our Website or any products or services we offer or provide though it.</li>
          <li>To allow you to participate in interactive features on our Website.</li>
          <li>In any other way we may describe when you provide the information.</li>
          <li>To fulfill any other purpose for which you provide authorization.</li>
        </ul>
        <br/>
        <p>We may also use your information to contact you about our own and third-parties' goods and services that may
          be of interest to you.</p>
        <br/>
        <p>We may use the information we have collected from you to enable us to display advertisements to our
          advertisers' target audiences. Even though we do not disclose your personal information for these purposes
          without your consent, if you click on or otherwise interact with an advertisement, the advertiser may assume
          that you meet its target criteria.</p>
        <br/>
        <p>Disclosure of Your Information</p>
        <br/>
        <p>We may disclose aggregated information about our users, and information that does not identify any individual
          or device, without restriction.</p>
        <br/>
        <p>We may disclose personal information that we collect or you provide as described in this privacy policy:</p>
        <br/>
        <ul>
          <li>To our subsidiaries and affiliates.</li>
          <li>To contractors, service providers and other third parties we use to support our business and in connection
            with a legitimate business interest.
          </li>
          <li>To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization,
            dissolution or other sale or transfer of some or all of Company's assets, whether as a going concern or as
            part of bankruptcy, liquidation or similar proceeding, in which personal information held by Company about
            our Website and App users is among the assets transferred.
          </li>
          <li>To third parties to market their products or services to you if you have consented to or not opted out of
            these disclosures.
          </li>
          <li>For any other purpose disclosed by us when you provide the information.</li>
          <li>To fulfill the purpose for which you provide authorization.</li>
          <li>With your consent.</li>
        </ul>
        <br/>
        <p>We may also disclose your personal information:</p>
        <br/>
        <ul>
          <li>To comply with any court order, subpoena, law or legal process, including to respond to any government or
            regulatory request.
          </li>
          <li>To enforce our rights arising from any contracts entered into between you and us and for billing and
            collection purposes.
          </li>
          <li>If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the
            Company, our customers or others. This includes exchanging information with other companies and
            organizations for the purposes of fraud protection and credit risk reduction.
          </li>
        </ul>
        <br/>
        <p>Choices About How We Use and Disclose Your Information</p>
        <br/>
        <p>We strive to provide you with choices regarding the personal information you provide to us. We have created
          mechanisms to provide you with the following control over your information:</p>
        <br/>
        <ul>
          <li><strong>Tracking Technologies and Advertising.</strong> You can set your browser to refuse all or some
            browser cookies, or to alert you when cookies are being sent. To learn how you can manage your Flash cookie
            settings, visit the Flash player settings page on Adobe's website. It may be possible to refuse to accept
            mobile cookies by activating the appropriate setting on your smartphone. If you disable or refuse cookies or
            block the use of other tracking technologies, some parts of the Website or App may then be inaccessible or
            not function properly.
          </li>
          <li>We do not control third parties' collection or use of your information to serve interest-based
            advertising. However these third parties may provide you with ways to choose not to have your information
            collected or used in this way. You can opt out of receiving targeted ads from members of the Network
            Advertising Initiative ("NAI") on the NAI's website.
          </li>
          <li>The location and tracking information is central to the App. To fully avoid the collection of such
            location and usage information you will need to cease using the App and removing it from your mobile device.
          </li>
          <li>If you do not want us to use your e-mail address or other contact information to promote our own or third
            parties' products or services, you can opt-out by logging into the Website or App and adjusting your user
            preferences in your account profile by checking or unchecking the relevant boxes or by sending us an e-mail
            stating your request to <a href="mailto:contactus@reflex-radio.com">contactus@reflex-radio.com</a>.
          </li>
          <li>If you do not want us to use information that we collect or that you provide to us to deliver
            advertisements according to our advertisers' target-audience preferences, you can opt-out by adjust your
            user advertising preferences in your account profile by checking or unchecking the relevant boxes or by
            sending us an e-mail stating your request to&nbsp;<a href="mailto:contactus@reflex-radio.com">contactus@reflex-radio.com</a>.
            If we have sent you a promotional e-mail, you may send us a return e-mail asking to be omitted from future
            e-mail distributions. This opt out does not apply to information provided to the Company as a result of a
            product purchase, warranty registration, product service experience or other transactions.
          </li>
          <li>If you do not want us to share your personal information with unaffiliated or non-agent third parties for
            advertising and marketing purposes, you can opt-out by logging into the App and adjusting your user
            preferences in your account profile by checking or unchecking the relevant boxes or by sending us an e-mail
            stating your request to&nbsp;<a href="mailto:contactus@reflex-radio.com">contactus@reflex-radio.com</a>.
          </li>
        </ul>
        <br/>
        <p>We do not control third parties' collection or use of your information to serve interest-based advertising.
          However these third parties may provide you with ways to choose not to have your information collected or used
          in this way.</p>
        <br/>
        <p>Your California Privacy Rights</p>
        <br/>
        <p>California Civil Code Section &sect; 1798.83 permits users of our Website and App that are California
          residents to request certain information regarding our disclosure of personal information to third parties for
          their direct marketing purposes. To make such a request, please send an e-mail to <a
              href="mailto:contactus@reflex-radio.com">contactus@reflex-radio.com</a>.</p>
        <br/>
        <p>Your GDPR Privacy Rights</p>
        <br/>
        <p>&nbsp;</p>
        <br/>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Pursuant to the General Data Protection
          Regulation (&ldquo;GDPR&rdquo;), certain individuals residing in the European Union, United Kingdom,
          Lichtenstein, Norway or Iceland are afforded additional privacy rights and protections. Please see our GDPR
          Privacy Notice Supplement (attached as Addendum 1) for further information.</p>
        <br/>
        <p>&nbsp;</p>
        <br/>
        <p>Changes to Our Privacy Policy</p>
        <br/>
        <p>It is our policy to post any changes we make to our privacy policy on this page. The date the privacy policy
          was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date
          active and deliverable e-mail address for you, and for periodically visiting our Website and this privacy
          policy to check for any changes.</p>
        <br/>
        <p>Contact Information</p>
        <br/>
        <p>To ask questions or comment about this privacy policy and our privacy practices, contact us at: <a
            href="mailto:contactus@reflex-radio.com">contactus@reflex-radio.com</a> or contact us at:</p>
        <br/>
        <h1>Aries Adaptive Media, LLC</h1>
        <br/>
        <h1>PO Box 470034</h1>
        <br/>
        <h1>8326 Pineville Matthews Road, Suite 407</h1>
        <br/>
        <h1>Charlotte, NC 28266-4722</h1>
        <br/>
        <h1>&nbsp;</h1>
        <br/>
        <h1>&nbsp;</h1>
        <br/>
        <p>&nbsp;</p>
        <br/>
        <h1><u>ADDENDUM 1</u></h1>
        <br/>
        <h1>&nbsp;</h1>
        <br/>
        <h1>General Data Protection Regulation (GDPR) Privacy Policy Addendum</h1>
        <br/>
        <h1>&nbsp;</h1>
        <br/>
        <h1><u>Definitions</u></h1>
        <br/>
        <h1>&nbsp;</h1>
        <br/>
        <h1>As used in this Addendum, capitalized terms otherwise not defined herein (including, without limitation,
          &ldquo;Personal Data&rdquo; and &ldquo;Processing&rdquo; shall have the meanings given in the GDPR; provided
          however that &ldquo;Personal Data&rdquo; shall mean any information relating to an identified or identifiable
          natural person (&lsquo;data subject&rsquo;); an identifiable natural person is one who can be identified,
          directly or indirectly, in particular by reference to an identifier such as a name, an identification number,
          location data, an online identifier or to one or more factors specific to the physical, physiological,
          genetic, mental, economic, cultural or social identity of that natural person.</h1>
        <br/>
        <h1>&nbsp;</h1>
        <br/>
        <p>Persons Under the Age of 16</p>
        <br/>
        <p>&nbsp;</p>
        <br/>
        <p>We do not knowingly collect or solicit Personal Data from anyone under the age of 16. If you are under 16,
          please do not attempt to register for the Site or send any Personal Data about yourself to us. If we learn
          that we have collected Personal Data from a child under age 16, we will delete that information as quickly as
          possible. If you believe that a child under 16 may have provided us Personal Data, please contact us at <a
              href="mailto:contactus@reflex-radio.com">contactus@reflex-radio.com</a>.</p>
        <br/>
        <p>&nbsp;</p>
        <br/>
        <p>Privacy Rights</p>
        <br/>
        <p>&nbsp;</p>
        <br/>
        <p>The GDPR grants the following additional rights to individuals living in the European Union and individuals
          monitored pursuant to the App or Website that reside in the European Union. As available and except as limited
          under applicable law, the rights afforded to individuals are:</p>
        <br/>
        <p>&nbsp;</p>
        <br/>
        <ul>
          <li>Right of access - the right to be informed of, and request access to, the Personal Data we process about
            you;
          </li>
          <li>Right to rectification - the right to request that we amend or update your Personal Data where it is
            inaccurate or incomplete;
          </li>
          <li>Right to erasure - the right to request that we delete your Personal Data;</li>
          <li>Right to restrict - the right to request that we temporarily or permanently stop processing all or some of
            your Personal Data;
          </li>
          <li>Right to object -
            <br/>
            <ul>
              <li>the right, at any time, to object to us processing your Personal Data on grounds relating to your
                particular situation;
              </li>
              <li>the right to object to your Personal Data being processed for direct marketing purposes;</li>
            </ul>
          </li>
          <li>Right to data portability - the right to request a copy of your Personal Data in electronic format and the
            right to transmit that personal data for use in another party&rsquo;s service; and
          </li>
          <li>Right not to be subject to automated decision-making - the right to not be subject to a decision based
            solely on automated decision making, including profiling, where the decision would have a legal effect on
            you or produce a similarly significant effect.
          </li>
          <li>Right to withdraw consent &ndash; to the extent that we are processing your Personal Data (if at all), you
            have the right to withdraw that consent at any time; provided, however that such withdrawal shall be
            provided on a case-by-case basis.
          </li>
        </ul>
        <br/>
        <p>&nbsp;</p>
        <br/>
        <h1><u>Basis for Processing</u></h1>
        <br/>
        <h1>&nbsp;</h1>
        <br/>
        <h1 class="figtree-bold-white-32px">We will only process your Personal Data if we have a lawful basis for doing so. Lawful bases for processing
          include consent, contractual necessity and our &ldquo;legitimate interests&rdquo; or the legitimate interest
          of others, such legitimate interests to include: contractual necessity, consent, improvement of our business,
          products and services, marketing of our products and services, notifying users of new updates, provision of
          customer support, protection from fraud, and compliance with legal requirements.</h1>
        <br/>
        <h1>&nbsp;</h1>
        <br/>
        <p>Changes to this Addendum</p>
        <br/>
        <p>It is our policy to post any changes we make to our privacy policy on this page. The date this Addendum or
          the Privacy Policy was last revised is identified at the top of the page. You are responsible for ensuring we
          have an up-to-date active and deliverable e-mail address for you, and for periodically visiting our Website
          and this privacy policy to check for any changes.</p>
        <br/>
        <h1>&nbsp;</h1>
      </md-tab>

      <md-tab class="tab-padding figtree-normal-white-14px" id="tab-pages p-60" md-label="Terms Of Use Agreement">
        <div style="text-align: center;">
          <h1 class="hdr figtree-bold-white-16px">Terms Of Use Agreement</h1><br/>
        </div>
        <p>This Terms of Use Agreement (the "Agreement"), last revised as of October 28, 2024 (the "Effective Date"), is by and between You, the customer ("Customer") and ARIES ADAPTIVE MEDIA LLC, a North Carolina limited liability company d/b/a Reflex Radio ("Company"). Customer hereby acknowledges that, by paying the Subscription fee, Customer acknowledges that Customer has read, understand and accept the following:</p>
        <ol>
            <li><strong>1. Definitions.</strong> Capitalized terms as used in this Agreement shall have the definitions as set forward on Schedule A, accessible at the third tab, “SCHEDULE A”. Schedule A is incorporated herein by reference.</li>
            <li>
                <strong>2. Services.</strong>
                <ul>
                    <li><strong>2.1 Services.</strong> Throughout the Term and at all times in connection with its actual or required performance under this Agreement, Company shall, in accordance with all terms and conditions set forth in this Agreement, provide to Customer and its Authorized Users the following services ("Services"):
                        <ul>
                            <li>(a) The hosting, management and operation of the Service Software and other services for remote electronic access and use by the Customer and its Authorized Users ("Hosted Services") as described in Schedule A;</li>
                            <li>(b) Service maintenance and the Support Services as set forth in Section 6; and</li>
                            <li>(c) Such other services as may be specified for the applicable Subscription.</li>
                        </ul>
                    </li>
                    <li><strong>2.2 Intentionally Reserved.</strong></li>
                    <li><strong>2.3 Compliance With Laws.</strong> Company shall comply with all applicable Laws as they concern this Agreement or the subject matter hereof, including by securing and maintaining all required and appropriate visas, work permits, business licenses and other documentation and clearances necessary for performance of the Services.</li>
                    <li><strong>2.4 Company Personnel.</strong> Company shall appoint a Company employee to serve as a primary contact with respect to the Services who will have the authority to act on behalf of Company in matters pertaining to the receipt and processing of support requests and the Support Services (the "Company Service Manager").</li>
                    <li><strong>2.5 Time of the Essence.</strong> Company acknowledges and agrees that time is of the essence with respect to its obligations under this Agreement and that prompt and timely performance of all such obligations, including all timetables and other requirements of this Agreement, is strictly required.</li>
                </ul>
            </li>
            <li>
                <strong>3. License Grant and Restrictions.</strong>
                <ul>
                    <li><strong>3.1 License Grant.</strong> Company hereby grants to Customer and its Affiliates, exercisable by and through their Authorized Users, a nonexclusive, royalty-free, personal, non-commercial, irrevocable (except as provided herein) and, solely as set forth in this Agreement, transferable and sublicensable, right and license (the “Subscription”) applicable to one Facility (or, if there is no applicable Facility, for one individual Authorized User as set forth in Schedule A) during the Term and such additional periods, if any, as Company is required to perform Services under this Agreement for such Services, to:
                        <ul>
                            <li>(a) Access and use the Hosted Services, including in operation with other software, hardware, systems, networks and services, for Customer's and its Affiliates' respective Permitted Uses, including for Processing Customer Data;</li>
                            <li>(b) Access and use the Services for all such non-production uses and applications as may be necessary or useful for the effective use of the Hosted Services hereunder, including for purposes of analysis, development, configuration, integration, testing, training, maintenance, support and repair, which access and use will be without charge and not included for any purpose in any calculation of Customer's or its Authorized Users' use of the Services, including for purposes of assessing any Fees or other consideration payable to Company or determining any excess use of the Hosted Services as described in Section 3.4; and</li>
                            <li>(c) Perform, display, execute, reproduce and modify (including to create improvements and derivative works of), and distribute and otherwise make available to Authorized Users, any Company Materials solely to the extent necessary to access or use the Services in accordance with the terms and conditions of this Agreement.</li>
                        </ul>
                    </li>
                    <li><strong>3.2 Non-Commercial Use.</strong> Customer hereby agrees that Customer is not using the Hosted Services for any commercial use and that Customer will not redistribute or transfer the Hosted Services or the Subscription. Company’s delivery of the Hosted Services to you does not transfer any commercial or promotional use rights to you, nor does it constitute a grant or waiver of any rights of the copyright owners.</li>
                    <li><strong>3.3 License Restrictions.</strong> Customer shall not:
                        <ul>
                            <li>(a) Rent, lease, lend, sell, sublicense, assign, distribute, publish, transfer or otherwise make the Hosted Services available to any third party, except as expressly permitted by this Agreement;</li>
                            <li>(b) Use or authorize the use of the Services or Documentation in any manner or for any purpose that is unlawful under applicable Law; or</li>
                            <li>(c) Perform any of the actions listed on Schedule C, attached hereto.</li>
                        </ul>
                    </li>
                    <li><strong>3.4 Excess Use.</strong> If, in Company’s reasonable discretion, Customer's uses of the Hosted Services exceeds the volume of use permitted by the license then in effect under Section 3 (including as to the number of uses, users, machines or locations), Customer shall pay Company the Fees attributable to the excess use in accordance with Section 8.</li>
                    <li><strong>3.5 Company Rights.</strong> In consideration for the rights granted to Customer under this Agreement, Customer hereby grants Company the right to:
                        <ul>
                            <li>(1) Allow Company to use the processor, bandwidth, and storage hardware on Customer’s device in order to facilitate the operation of the Services;</li>
                            <li>(2) To provide advertising and other information to Customer; and</li>
                            <li>(3) To allow Company’s business partners and Affiliates to do the same.</li>
                        </ul>
                    </li>
                    <li><strong>3.6 Non-Affiliated Services.</strong> Customer hereby acknowledges that the Hosted Services may be used in conjunction with Apple Services including, without limitation, Apple Music, and Spotify. Customer hereby acknowledges and agrees that the Subscription and any use of the Hosted Services shall not grant Customer any license to use Apple Music or Spotify, and that any use of Apple Music and/or Spotify shall be governed by the terms and conditions thereof.</li>
                </ul>
            </li>
            <li><strong>4. Service Preparation.</strong> Promptly upon the parties' review of this Agreement and Customer’s purchase of a Subscription, Company shall take all steps necessary to make the Services procured thereunder ready and available for Customer's use in accordance with this Agreement.</li>
            <li><strong>5. Service Availability.</strong> Company shall use commercially reasonable efforts to make the Hosted Services Available during the Term and any additional periods during which Company does or is required to perform any Hosted Services (each such calendar month, a "Service Period"). "Available" means the Hosted Services are available and operable for access and use by Customer and its Authorized Users over the Internet in material conformity with the Specifications.</li>
            <li>
                <strong>6. Support and Maintenance Services.</strong>
                <ul>
                    <li><strong>6.1 Support Services.</strong> Company shall provide Hosted Service maintenance and support services (collectively, "Support Services") in accordance with the provisions of this Section 6. The Support Services are included in the Services, and Company shall not assess any additional Fees, costs or charges for such Support Services.</li>
                    <li><strong>6.2 Service Maintenance.</strong> Company shall continuously maintain the Hosted Services to optimize Availability that meets or exceeds the Availability Requirement. Such maintenance services shall include providing to Customer and its Authorized Users:
                        <ul>
                            <li>(a) All updates, bug fixes, enhancements, new releases, new versions and other improvements to the Hosted Services, including the Service Software, that Company provides at no additional charge to its other similarly situated customers; and</li>
                            <li>(b) All such services and repairs as are required to maintain the Hosted Services or are ancillary, necessary or otherwise related to Customer's or its Authorized Users' access to or use of the Hosted Services, so that the Hosted Services operate properly in accordance with this Agreement and the Specifications.</li>
                        </ul>
                    </li>
                </ul>
            </li>
            <li>
                <strong>7. Term and Termination.</strong>
                <ul>
                    <li><strong>7.1 Term.</strong> The initial term of this Agreement commences as of the Effective Date and will continue in automatically on a month-to-month basis from such date unless and until terminated as provided under this Agreement (the "Initial Term").</li>
                    <li><strong>7.2 Renewal.</strong> Unless this Agreement is terminated earlier pursuant to its provisions, this Agreement shall automatically renew for additional successive thirty (30) day terms (each a "Renewal Term"), unless either party terminates the Subscription prior to the expiration of the then pending term (the Initial Term together with any Renewal Terms, collectively, the "Term").</li>
                    <li><strong>7.3 Termination for Convenience.</strong> At any time without cause and without causing any breach or incurring any additional obligation, liability or penalty, either party may terminate the Subscription and this Agreement prior to the expiration of the Term. The termination will take effect the day after the last day of the current Term.</li>
                    <li><strong>7.4 Intentionally Reserved.</strong></li>
                    <li><strong>7.5 Effect of Termination.</strong> The expiration or termination of this Agreement will not effectuate a termination of the Term then in effect and not otherwise expressly terminated, and the terms and conditions of this Agreement will continue in effect with respect to any such Subscription until its expiration or termination as set forth herein. In addition, unless otherwise expressly provided in this Agreement, upon and after the termination or expiration of this Agreement and the Subscription for any or no reason:
                        <ul>
                            <li>(i) Subject to the continuing rights, licenses, and obligations of either party under this Agreement, including this Section 7.5, all licenses granted hereunder will immediately terminate and the respective parties shall cease all activities concerning, including all use of, in the case of Customer, the expired or terminated Hosted Services and related Company Materials, and, in the case of Company, the Customer Data; and</li>
                            <li>(ii) Customer shall pay to Company all undisputed charges and amounts due and payable to Company, if any, for Services actually performed.</li>
                        </ul>
                    </li>
                    <li><strong>7.6 Survival.</strong> The rights, obligations, and conditions set forth in this Section 7.6 and Section 1 (Definitions), Section 7.4 (Effect of Termination; Data Retention), Section 9 (Ownership), Section (Confidentiality), Section 10 (Personal Information), Section 11 (Security), Section 13 (Limitations of Liability), Section 14 (Representations and Warranties), and Section 16 (Effect of Company Bankruptcy) and Section 17 (General Provisions), and any right, obligation, or condition that, by its express terms or nature and context, is intended to survive the termination or expiration of this Agreement, shall survive any such termination or expiration hereof.</li>
                </ul>
            </li>
            <li>
                <strong>8. Fees and Expenses.</strong>
                <ul>
                    <li><strong>8.1 Fees.</strong> Subject to the terms and conditions of this Agreement and the Subscription, including the provisions of this Section 8, Customer shall pay the Subscription fees as set forth on Schedule A, which shall be determined by Company in Company’s sole discretion, subject to such increases and adjustments as may be permitted pursuant to Section 8.2 ("Fees").</li>
                    <li><strong>8.2 Fees During Renewal Terms.</strong> Company's Fees are fixed during the Initial Term. Company may increase Fees for any Renewal Term by providing written notice to Customer at least sixty (60) calendar days prior to the commencement of such Renewal Term. No increase in Fees is effective unless made in compliance with the provisions of this Section 8.2.</li>
                    <li><strong>8.3 Responsibility for Costs.</strong> Company shall be responsible for all costs and expenses incurred in or incidental to the performance of Services, including all fees, fines, licenses, bonds, or taxes required of or imposed against Company, except to the extent any such fines result from the negligence or willful misconduct of Customer.</li>
                    <li><strong>8.4 Taxes.</strong> All Fees and amounts set forth in this Agreement are exclusive of taxes. Customer shall be solely responsible for all sales, service, value-added, use, excise, consumption, and any other taxes, duties, and charges of any kind, if any, imposed by any federal, state, or local governmental entity on any amounts payable by Customer under this Agreement other than any taxes imposed on, or with respect to, Company's income, revenues, gross receipts, personnel, real or personal property, or other assets.</li>
                    <li><strong>8.5 Intentionally Deleted.</strong></li>
                    <li><strong>8.6 Payment Terms.</strong> Customer shall pay amounts payable and due hereunder with the credit card on file in the amounts set forth on Schedule A.</li>
                </ul>
            </li>
            <li>
                <strong>9. Ownership.</strong>
                <ul>
                    <li><strong>9.1 Limited License to Use Customer Data.</strong> Subject to the terms and conditions of this Agreement, Customer and each of its Affiliates licensed hereunder hereby grants Company a limited, royalty-free, fully-paid up, non-exclusive, non-transferable and non-sublicensable license to Process the Customer Data in the United States strictly as instructed by Customer or an Authorized User and solely as necessary to provide the Services for Customer's and such Affiliates' benefit as provided in this Agreement for so long as Customer or any Authorized User uploads or stores such Customer Data for Processing by or on behalf of the Company on the Company Systems.</li>
                    <li><strong>9.2 Ownership of Company Materials.</strong> As between Customer and Company, Company is and will remain the sole and exclusive owner of all right, title, and interest in and to the Company Materials, including all Intellectual Property Rights relating thereto, subject only to the license granted to Customer in Section (c).</li>
                    <li><strong>9.3 No Implied Rights.</strong> Except for the limited license expressly provided (a) in this Section 9, nothing contained in this Agreement shall be construed as granting Company or any third party any right, title, or interest in or to any Customer Data whether by implication, estoppel, or otherwise.</li>
                </ul>
            </li>
            <li>
                <strong>10. Personal Information.</strong>
                <ul>
                    <li><strong>10.1 Definition and Permitted Use.</strong>
                        <ul>
                            <li>(a) For purposes of this Agreement, "Personal Information" means any information that any of the Company Personnel collects, receives, or obtains, from or on behalf of Customer or any of its Authorized Users that does or can identify a specific individual or by or from which a specific individual may be identified, contacted, or located, such as the individual's name, address, social security number, etc., and any other information relating to an identified or identifiable individual. Personal Information includes such information of or pertaining to Customer's personnel, directors, officers, agents, suppliers, contractors, investors, or customers and all "nonpublic personal information," as defined under the Gramm-Leach-Bliley Act (15 U.S.C. § 6801 et seq.), and "Personal Data" as that term is defined in EU Data Protection Directive (Directive 95/46/EEC) on the protection of individuals with regard to processing of personal data and the free movement of such data, and all rules and regulations issued under any of the foregoing. Notwithstanding the foregoing, Company shall be entitled to utilize the Personal Information as set forth in Section 3.5 herein.</li>
                        </ul>
                    </li>
                    <li><strong>10.2 Ownership and Treatment of Personal Information.</strong> As between Customer and Company, Customer is and shall remain the sole and exclusive owner of all right, title, and interest in and to Personal Information.</li>
                </ul>
            </li>
            <li><strong>11. Security.</strong> Throughout the Term and at all times in connection with its actual or required performance of the Services hereunder, Company shall provide technical and organizational safeguards against accidental, unlawful, or unauthorized access to or use, destruction, loss, alteration, disclosure, transfer, commingling, or Processing of such information that ensure a level of security appropriate to the risks presented by the Processing of Customer's Confidential Information and the nature of such Confidential Information, consistent with applicable industry practice and standards.</li>
            <li>
                <strong>12. Indemnification.</strong> Customer shall indemnify, defend, and hold Company and its Affiliates and each of Company's and its Affiliates' respective officers, directors, employees, agents, successors, and assigns (each, a "Company Indemnitee") harmless from and against all Losses incurred by any of them arising out of or resulting from any Action to the extent that such Action arises out of, results from, or alleges:
                <ul>
                    <li>(a) Any claim that any Customer Data is unlawful or actually does or threatens to infringe, misappropriate, or otherwise violate any United States Intellectual Property Rights or other rights of any third party, provided however, that Customer shall have no liability or obligation with respect to any Action or Loss to the extent that such Action or Loss arises out of or results from any unauthorized access to or use, disclosure, or other Processing of Customer Data, including Personal Information, by or on behalf of Company, or through or enabled by the Company Systems, whether authorized by Company, due to a security breach, or otherwise; or</li>
                    <li>(b) Any use of the Hosted Services or Service Software by Customer or any Authorized User that is beyond the scope of or otherwise fails to conform to this Agreement or any authorization or approval given in writing by Company to Customer or such Authorized User. The remedies set forth in this paragraph are Company's exclusive remedies with respect to any Action or Loss described in this Section 12.</li>
                </ul>
            </li>
            <li>
                <strong>13. Limitations of Liability.</strong>
                <ul>
                    <li><strong>13.1 EXCLUSION OF INDIRECT DAMAGES.</strong> EXCEPT AS OTHERWISE PROVIDED HEREIN, IN NO EVENT WILL EITHER PARTY BE LIABLE UNDER THIS AGREEMENT FOR ANY CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, OR PUNITIVE DAMAGES. IN ANY EVENT, COMPANY’S LIABILITY TO CUSTOMER SHALL NOT EXCEED THE SUBSCRIPTION FEE PAID TO COMPANY BY CUSTOMER.</li>
                    <li><strong>13.2 Limitation of Company Liability.</strong> Company and Customer acknowledge that Customer may use the Service Software to provide mass communication to Customer’s employees and contractors. Company shall not be responsible for the content of any communication by Customer pursuant to the Service Software. Customer hereby releases Company from any liability for the content of Customer’s communications using the Service Software.</li>
                    <li><strong>13.3 Exceptions.</strong> The exclusions and limitations in Section 13.1 shall not apply to:
                        <ul>
                            <li>(a) Losses arising out of or relating to a party's failure to comply with its obligations under Section 9 (Ownership), Section 10 (Personal Information), or Section 11 (Security);</li>
                            <li>(b) A party's indemnification obligations under Section 12 (Indemnification);</li>
                            <li>(c) Losses arising out of or relating to a party's gross negligence or more culpable conduct, including any willful misconduct or intentional wrongful acts;</li>
                            <li>(d) Losses for death, bodily injury, or damage to real or tangible personal property arising out of or relating to a party's negligent or more culpable acts or omissions;</li>
                            <li>(e) Losses arising from or relating to a party's violation of Law; or</li>
                            <li>(f) A party's obligation to pay attorneys' fees and court costs in accordance with Section 17.15.</li>
                        </ul>
                    </li>
                </ul>
            </li>
            <li>
                <strong>14. Representations and Warranties.</strong>
                <ul>
                    <li><strong>14.1 Mutual Representations and Warranties.</strong> Each party represents and warrants to the other party that:
                        <ul>
                            <li>(a) It is duly organized, validly existing, and in good standing as a corporation or other entity as represented herein under the laws and regulations of its jurisdiction of incorporation, organization, or chartering; and</li>
                            <li>(b) It has, and throughout the Term and any additional periods during which it does or is required to perform the Services will retain, the full right, power, and authority to enter into this Agreement and perform its obligations hereunder.</li>
                        </ul>
                    </li>
                    <li><strong>14.2 DISCLAIMER.</strong> EXCEPT FOR THE EXPRESS WARRANTIES IN THIS AGREEMENT, EACH PARTY HEREBY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE UNDER OR IN CONNECTION WITH THIS AGREEMENT OR ANY SUBJECT MATTER HEREOF. CUSTOMER UNDERSTANDS AND AGREES THAT THE SERVICES AND THE SOFTWARE ARE PROVIDED “AS IS”. NEITHER COMPANY NOR ANY OWNER OF ANY CONTENT ON THE SOFTWARE WARRANTS THAT THE SOFTWARE IS FREE OF MALWARE OR OTHER HARMFUL COMPONENTS.</li>
                </ul>
            </li>
            <li>
                <strong>15. Force Majeure.</strong>
                <ul>
                    <li><strong>15.1 Force Majeure Events.</strong> Neither party shall be liable or responsible to the other party, or be deemed to have defaulted under or breached this Agreement, for any failure or delay in fulfilling or performing any term hereof, when and to the extent such failure or delay is caused by: acts of God, flood, fire or explosion, war, terrorism, invasion, riot or other civil unrest, embargoes or blockades in effect on or after the date of this Agreement, national or regional emergency, strikes, labor stoppages or slowdowns or other industrial disturbances, or any passage of law or governmental order, rule, regulation or direction, or any action taken by a governmental or public authority, including imposing an embargo, export or import restriction, quota or other restriction or prohibition (each of the foregoing, a "Force Majeure Event"), in each case provided that:
                        <ul>
                            <li>(a) Such event is outside the reasonable control of the affected party;</li>
                            <li>(b) The affected party gives prompt written notice to the other party, stating the period of time the occurrence is expected to continue;</li>
                            <li>(c) The affected party uses diligent efforts to end the failure or delay and minimize the effects of such Force Majeure Event.</li>
                        </ul>
                    </li>
                    <li><strong>15.2 Exclusions; Non-suspended Obligations.</strong> Notwithstanding the foregoing or any other provisions of this Agreement:
                        <ul>
                            <li>(a) In no event shall any of the following be considered a Force Majeure Event:
                                <ul>
                                    <li>(i) Shutdowns, disruptions, or malfunctions of the Company Systems or any of Company's telecommunication or internet services other than as a result of general and widespread internet or telecommunications failures that are not limited to the Company Systems; or</li>
                                    <li>(ii) The delay or failure of any Company Personnel to perform any obligation of Company hereunder unless such delay or failure to perform is itself by reason of a Force Majeure Event;</li>
                                </ul>
                            </li>
                            <li>(b) No Force Majeure Event shall modify or excuse Company's obligations under Section 5 (Service Availability and Service Availability Credits), Section 9 (Ownership), Section 10 (Personal Information), Section 11 (Security), or Section 12 (Indemnification), or any Availability Requirement, Support Service Level Requirement, Service Availability Credit or Service Level Credit obligations under this Agreement.</li>
                        </ul>
                    </li>
                </ul>
            </li>
            <li>
                <strong>16. Effect of Company Bankruptcy.</strong> 
                <ul>
                  All rights and licenses granted by Company under this Agreement (which is supplementary to this Agreement) are and shall be deemed to be rights and licenses to "intellectual property," and the subject matter of this Agreement, including the Services and all escrow deposit materials comprising or relating to any of the Services, is and shall be deemed to be "embodiments" of "intellectual property" for purposes of and as such terms are used in and interpreted under section 365(n) of the United States Bankruptcy Code (the "Code") (11 U.S.C. § 365(n) (2010)). Customer shall have the right to exercise all rights and elections under the Code and all other applicable bankruptcy, insolvency and similar laws with respect to this Agreement and the subject matter hereof and thereof. Without limiting the generality of the foregoing, if Company or its estate becomes subject to any bankruptcy or similar proceeding:
                    <li>(a) Subject to Customer's rights of election, all rights and licenses granted to Customer under this Agreement will continue subject to the respective terms and conditions hereof and thereof, and will not be affected, even by Company's rejection of this Agreement;</li>
                    <li>(b) Customer shall be entitled to a complete duplicate of (or complete access to, as appropriate) all such intellectual property and embodiments of intellectual property, and the same, if not already in Customer's possession, shall be promptly delivered to Customer, unless Company elects to and does in fact continue to perform all of its obligations under this Agreement.</li>
                </ul>
            </li>
            <li>
                <strong>17. General Provisions.</strong>
                <ul>
                    <li><strong>17.1 Further Assurances.</strong> Each party shall, upon the request, and at the sole cost and expense, of the other party, promptly execute such documents and perform such acts as may be necessary to give full effect to the terms of this Agreement.</li>
                    <li><strong>17.2 Relationship of the Parties.</strong> The relationship between the parties is that of independent contractors. Nothing contained in this Agreement shall be construed as creating any agency, partnership, joint venture or other form of joint enterprise, employment or fiduciary relationship between the parties, and neither party shall have authority to contract for or bind the other party in any manner whatsoever.</li>
                    <li><strong>17.3 Public Announcements.</strong> Neither party shall issue or release any announcement, statement, press release or other publicity or marketing materials relating to this Agreement or, unless expressly permitted under this Agreement, otherwise use the other party's trademarks, service marks, trade names, logos, domain names or other indicia of source, association or sponsorship, in each case, without the prior written consent of the other party.</li>
                    <li><strong>17.4 Notices.</strong> All notices, requests, consents, claims, demands, waivers and other communications hereunder, other than routine communications having no legal effect, shall be in writing and addressed to the Company at: 8326 Pineville Matthews Road, Suite 407, #470034, Charlotte, NC 28226.</li>
                    <li><strong>17.5 Interpretation.</strong> For purposes of this Agreement:
                        <ul>
                            <li>(a) The words "include," "includes" and "including" are deemed to be followed by the words "without limitation";</li>
                            <li>(b) The word "or" is not exclusive; and</li>
                            <li>(c) The words "herein," "hereof," "hereby," "hereto" and "hereunder" refer to this Agreement as a whole; and all personal pronouns, whether used in the feminine, masculine, or neuter gender, include all other genders and the singular shall include the plural and vice versa.</li>
                        </ul>
                    </li>
                    <li><strong>17.6 Headings.</strong> The headings in this Agreement are for reference only and shall not affect the interpretation of this Agreement.</li>
                    <li><strong>17.7 Entire Agreement.</strong> This Agreement and other Schedules and Exhibits, and any other documents, agreements or instruments incorporated by reference herein, constitutes the sole and entire agreement of the parties to this Agreement with respect to the subject matter contained herein, and supersedes all prior and contemporaneous understandings and agreements, both written and oral, with respect to such subject matter. In the event of any conflict between the terms of this Agreement and those of any Schedule, Exhibit or other document, the terms of this Agreement shall control.</li>
                    <li><strong>17.8 Assignment.</strong> Customer shall not assign or otherwise transfer any of its rights, or delegate or otherwise transfer any of its obligations or performance, under this Agreement.</li>
                    <li><strong>17.9 No Third-party Beneficiaries.</strong> This Agreement is for the sole benefit of the parties hereto and their respective successors and assigns and nothing herein, express or implied, is intended to or shall confer on any other person or entity any legal or equitable right, benefit or remedy of any nature whatsoever under or by reason of this Agreement.</li>
                    <li><strong>17.10 Amendment and Modification; Waiver.</strong> This Agreement may be amended at any time by Company. In the event of any material change to this Agreement, Company will provide electronic notice and will provide access to the updated Agreement on the Software. If Customer does not wish to continue using the Services pursuant to the amended Agreement, Customer may terminate the Subscription and this Agreement as set forth herein.</li>
                    <li><strong>17.11 Severability.</strong> If any term or provision of this Agreement is invalid, illegal or unenforceable in any jurisdiction, such invalidity, illegality or unenforceability shall not affect any other term or provision of this Agreement or invalidate or render unenforceable such term or provision in any other jurisdiction. Upon such determination that any term or other provision is invalid, illegal or unenforceable, the parties hereto shall negotiate in good faith to modify this Agreement so as to effect the original intent of the parties as closely as possible in a mutually acceptable manner in order that the transactions contemplated hereby be consummated as originally contemplated to the greatest extent possible.</li>
                    <li><strong>17.12 Governing Law; Submission to Jurisdiction.</strong>
                        <ul>
                            <li>(a) This Agreement and all related documents, and all matters arising out of or relating to this Agreement, are governed by, and construed in accordance with, the laws of the State of North Carolina.</li>
                            <li>(b) Any legal suit, action or proceeding arising out of this Agreement or the licenses granted hereunder shall be instituted exclusively in the federal courts of the United States or the courts of the State of North Carolina in each case located in the city of Charlotte and County of Mecklenburg, and each party irrevocably submits to the jurisdiction of such courts in any such suit, action or proceeding. Service of process, summons, notice or other document by mail to such party's address set forth herein shall be effective service of process for any suit, action or other proceeding brought in any such court.</li>
                        </ul>
                    </li>
                    <li><strong>17.13 Waiver of Jury Trial.</strong> Each party irrevocably and unconditionally waives any right it may have to a trial by jury in respect of any legal action arising out of or relating to this Agreement or the transactions contemplated hereby.</li>
                    <li><strong>17.14 Equitable Relief.</strong> Each party to this Agreement acknowledges and agrees that:
                        <ul>
                            <li>(a) A breach or threatened breach by such party of any of its obligations under this Agreement would give rise to irreparable harm to the other party for which monetary damages would not be an adequate remedy; and</li>
                            <li>(b) In the event of a breach or a threatened breach by such party of any such obligations, the other party hereto shall, in addition to any and all other rights and remedies that may be available to such party at law, at equity or otherwise in respect of such breach, be entitled to equitable relief, including a temporary restraining order, an injunction, specific performance and any other relief that may be available from a court of competent jurisdiction, without any requirement to post a bond or other security, and without any requirement to prove actual damages or that monetary damages will not afford an adequate remedy.</li>
                        </ul>
                    </li>
                    <li><strong>17.15 Attorneys' Fees.</strong> In the event that any action, suit, or other legal or administrative proceeding is instituted or commenced by either party hereto against the other party arising out of or related to this Agreement, the prevailing party shall be entitled to recover its reasonable attorneys' fees, expert witness fees and out-of-pocket court costs from the non-prevailing party.</li>
                    <li><strong>17.16 Schedules and Exhibits.</strong> All Exhibits that are referenced herein and attached hereto, or are signed by both parties on or after the Effective Date, are hereby incorporated by reference.</li>
                </ul>
            </li>
        </ol>
        <br>
        <br>
        <br>
        <br>
        <br>
        <div style="text-align: center;">
          <h2><strong>SCHEDULE A</strong></h2>
          <p><strong>SUBSCRIPTIONS, PRICING AND SPECIFICATIONS</strong></p>
        </div>
        <br/>

        <p>Accessible at: the third tab “SCHEDULE A”.</p>
        <br/>
        <br/>
        <br/>
        <br/>
        <div style="text-align: center;">
          <h2><strong>SCHEDULE B</strong></h2>
          <p><strong>DEFINITIONS</strong></p>
        </div>
        <ol>
          <li><strong>"Affiliate"</strong>: Of a Person means any other Person that directly or indirectly, through one or more intermediaries:
            <ul>
              <li>controls,</li>
              <li>is controlled by, or</li>
              <li>is under common control with such Person.</li>
            </ul>
            The term "control" (including the terms "controlled by" and "under common control with") means the direct or indirect power to direct or cause the direction of the management and policies of a Person, whether through the ownership of voting securities, by contract, or otherwise/ownership of more than fifty percent (50%) of the voting securities of a Person.
          </li>
          <li><strong>"Agreement"</strong>: Has the meaning set forth in the preamble.</li>
          <li><strong>"Authorized Users"</strong>: Means all Persons authorized by Customer to access and use the Services through Customer's account under this Agreement. For avoidance of doubt, Authorized Users shall include the employees of Customer working at the Facility for which Customer has purchased a single Subscription.</li>
          <li><strong>"Available"</strong>: Has the meaning set forth in <strong>Section 5</strong>.</li>
          <li><strong>"Business Day"</strong>: Means a day other than a Saturday, Sunday, or other federal holiday.</li>
          <li><strong>"Code"</strong>: Has the meaning set forth in <strong>Section 16</strong>.</li>
          <li><strong>"Company"</strong>: Has the meaning set forth in the preamble.</li>
          <li><strong>"Company Indemnitee"</strong>: Has the meaning set forth in <strong>Section 12</strong>.</li>
          <li><strong>"Company Materials"</strong>: Includes all devices, documents, data, know-how, methods, processes, software, and other inventions, works, technologies, and materials, including any and all Service Software, Documentation, computer hardware, programs, reports, and specifications, client software, and deliverables provided or used by Company in connection with performing the Services, in each case developed or acquired by the Service Company independently of this Agreement.</li>
          <li><strong>"Company Personnel"</strong>: Means all employees and agents of Company involved in the performance of Services.</li>
          <li><strong>"Company Security Officer"</strong>: Has the meaning set forth in <strong>Section 2.4</strong>.</li>
          <li><strong>"Company Service Manager"</strong>: Has the meaning set forth in <strong>Section 2.4</strong>.</li>
          <li><strong>"Customer"</strong>: Has the meaning set forth in the preamble.</li>
          <li><strong>"Customer Data"</strong>: Means any and all information, data, materials, works, expressions, or other content, including any that are:
            <ul>
              <li>(a) Uploaded, submitted, posted, transferred, transmitted, or otherwise provided or made available by or on behalf of Customer or any Authorized User for Processing by or through the Hosted Services.</li>
              <li>(b) Collected, downloaded, or otherwise received by Company or the Hosted Services for Customer or any Authorized User pursuant to this Agreement or at the written request or instruction of Customer or such Authorized User.</li>
            </ul>
            All output, copies, reproductions, improvements, modifications, adaptations, translations, and other derivative works of, based on, derived from, or otherwise using any Customer Data are themselves also Customer Data. For the avoidance of doubt, Customer Data includes all User Data and Personal Information but does not include any Company Materials.
          </li>
          <li><strong>"Documentation"</strong>: Means all generally available documentation relating to the Services, including all user manuals, operating manuals, and other instructions, specifications, documents, and materials, in any form or media, that describe any component, feature, requirement, or other aspect of the Services, including any functionality, testing, operation, or use thereof.</li>
          <li><strong>"Effective Date"</strong>: Has the meaning set forth in the preamble.</li>
          <li><strong>"Exceptions"</strong>: Has the meaning set forth in <strong>Section 5</strong>.</li>
          <li><strong>"Facility"</strong>: Shall refer to each gymnasium or other building in which Customer provides fitness training services to an end user.</li>
          <li><strong>"Fees"</strong>: Has the meaning set forth in <strong>Section 8.1</strong>.</li>
          <li><strong>"Force Majeure Event"</strong>: Has the meaning set forth in <strong>Section 15.1</strong>.</li>
          <li><strong>"Hosted Services"</strong>: Has the meaning set forth in <strong>Section 2.1(a)</strong>.</li>
          <li><strong>"Initial Term"</strong>: Has the meaning set forth in <strong>Section 7.1</strong>.</li>
          <li><strong>"Intellectual Property Rights"</strong>: Means any and all rights comprising or relating to:
            <ul>
              <li>(a) Patents, patent disclosures, and inventions (whether patentable or not).</li>
              <li>(b) Trademarks, service marks, trade dress, trade names, logos, corporate names, and domain names, together with all of the goodwill associated therewith.</li>
              <li>(c) Authorship rights, copyrights, and copyrightable works (including computer programs) and rights in data and databases.</li>
              <li>(d) Trade secrets, know-how, and other confidential information.</li>
              <li>(e) All other intellectual property rights, whether registered or unregistered, including applications for, renewals, or extensions of such rights, and all similar or equivalent rights or forms of protection provided by applicable Law in any jurisdiction worldwide.</li>
            </ul>
          </li>
          <li><strong>"Law"</strong>: Means any statute, law, ordinance, regulation, rule, code, order, constitution, treaty, common law, judgment, decree, or other requirement or rule of any federal, state, local, or foreign government or political subdivision thereof, or any arbitrator, court, or tribunal of competent jurisdiction.</li>
          <li><strong>"Loss"</strong>: Means all losses, damages, liabilities, deficiencies, claims, actions, judgments, settlements, interest, awards, penalties, fines, costs, or expenses of whatever kind, including reasonable attorneys' fees and the costs of enforcing any right to indemnification hereunder and the cost of pursuing any insurance Companys.</li>
          <li><strong>"Losses"</strong>: Has the same definition as "Loss".</li>
          <li><strong>"Permitted Uses"</strong>: Means any use of the Services by Customer or any Authorized User for the benefit of Customer’s internal business operations or for scheduling services.</li>
          <li><strong>"Person"</strong>: Means an individual, corporation, partnership, joint venture, limited liability company, governmental authority, unincorporated organization, trust, association, or other entity.</li>
          <li><strong>"Personal Information"</strong>: Has the meaning set forth in <strong>Section 10.1(a)</strong>.</li>
          <li><strong>"Process"</strong>: Means to perform any operation or set of operations on any data, information, material, work, expression, or other content, including to:
            <ul>
              <li>(a) Collect, receive, input, upload, download, record, reproduce, store, organize, combine, log, catalog, cross-reference, manage, maintain, copy, adapt, alter, translate, or make other improvements or derivative works.</li>
              <li>(b) Process, retrieve, output, consult, use, disseminate, transmit, submit, post, transfer, disclose, or otherwise provide or make available.</li>
              <li>(c) Block, erase, or destroy.</li>
            </ul>
          </li>
          <li><strong>"Renewal Term"</strong>: Has the meaning set forth in <strong>Section 7.2</strong>.</li>
          <li><strong>"Representatives"</strong>: Means a party's and its Affiliates' respective employees, officers, directors, consultants, legal advisors, and, with respect to Customer, solely those of its independent contractors or service providers that are Authorized Users.</li>
          <li><strong>"Service Period"</strong>: Has the meaning set forth in <strong>Section 5</strong>.</li>
          <li><strong>"Service Software"</strong>: Means the Company software application or applications and any third-party or other software, and all new versions, updates, revisions, improvements, and modifications of the foregoing, that Company provides remote access to and use of as part of the Services.</li>
          <li><strong>"Services"</strong>: Has the meaning set forth in <strong>Section 2.1</strong>.</li>
          <li><strong>"Source Code"</strong>: Means the human-readable source code of the Service Software, including all related flow charts and technical documentation, sufficient for a programmer to understand, build, operate, and maintain the software and develop modifications.</li>
          <li><strong>"Specifications"</strong>: Means the specifications for the Services set forth in the applicable Subscription and, to the extent consistent with and not limiting of the foregoing, the Documentation.</li>
          <li><strong>"Subscription"</strong>: Has the meaning set forth in <strong>Section 3.1</strong>.</li>
          <li><strong>"Support Services"</strong>: Has the meaning set forth in <strong>Section 6</strong>.</li>
          <li><strong>"Term"</strong>: Has the meaning set forth in <strong>Section 7.2</strong>.</li>
          <li><strong>"Territory"</strong>: Means worldwide.</li>
          <li><strong>"User Data"</strong>: means any and all information reflecting the access or use of the Hosted Services by or on behalf of Customer or any Authorized User, including any end user profile-, visit-, session-, impression-, click through- or click stream- data and any statistical or other analysis, information or data based on or derived from any of the foregoing.</li>
        </ol>
        <br/>
        <br/>
        <br/>
        <div style="text-align: center;">
          <h2><strong>SCHEDULE C</strong></h2>
          <p><strong>PROHIBITED USES</strong></p>
        </div>
        <p>The following actions by Customer are strictly prohibited:</p>
        <ol>
            <li>1. Copying, redistributing, reproducing, “ripping,” recording, transferring, performing or displaying to the public, broadcasting, or making available to the public any part of the Services or the Software, or otherwise making any use of the Services or the Software which is not expressly permitted under the Agreements or applicable law or which otherwise infringes the intellectual property rights (such as copyright) in the Services or the Software or any part of it (provided, however that an Authorized User may play audio content over video content provided by Customer, to the extent that such audio content is provided by the Software and not a third-party application);</li>
            <li>2. Using the Services to import or copy any local files that you do not have the legal right to import or copy in this way;</li>
            <li>3. Transferring copies of cached content from an authorized Device to any other Device via any means;</li>
            <li>4. Reverse-engineering, decompiling, disassembling, modifying, or creating derivative works of the Services, content or any part thereof except to the extent permitted by applicable law.</li>
            <li>5. Circumventing any technology used by Company, its licensors, or any third party to protect the Software or the Service;</li>
            <li>6. Selling, renting, sublicensing, or leasing of any part of the Services or the Software;</li>
            <li>7. Circumventing any territorial restrictions applied by Company or its licensors;</li>
            <li>8. Artificially increasing play counts, follow counts, or otherwise manipulating the Service by:
                <ol type="i">
                    <li>Using any bot, script, or other automated process;</li>
                    <li>Providing or accepting any form of compensation (financial or otherwise); or</li>
                    <li>Any other means;</li>
                </ol>
            </li>
            <li>9. Removing or altering any copyright, trademark, or other intellectual property notices contained on the Software or the Service or provided through the Service (including for the purpose of disguising or changing any indications of the ownership or source of any content);</li>
            <li>10. Circumventing or blocking advertisements in the Services, or creating or distributing tools designed to block advertisements in the Services;</li>
            <li>11. Providing Customer’s password to any other person or using any other person’s username and password;</li>
            <li>12. “Crawling” the Services or otherwise using any automated means (including bots, scrapers, and spiders) to view, access, or collect information from Company or the Services;</li>
            <li>13. Selling a user account or playlist, or otherwise accepting or offering to accept any compensation, financial or otherwise, to influence the name of an account or playlist or the Software included on an account or playlist; or</li>
            <li>14. Artificially promoting content by automated means or otherwise.</li>
        </ol>



      </md-tab>

      <md-tab class="tab-padding figtree-normal-white-14px" id="tab-posts" md-label="Schedule A">
        <h1 class="hdr figtree-bold-white-16px">Schedule A</h1><br/>
        <p>SUBSCRIPTIONS, PRICING AND SPECIFICATIONS</p>
        <p>The fees and terms contained in this Schedule A are subject to change from time to time in Company&rsquo;s
          sole discretion. All payments shall be in US dollars and made by the credit card(s) or applicable ACH
          information associated with the Subscription, as authorized by Schedule A-1 attached hereto.</p>
        <p>&nbsp;</p>
        <ol>
          <li><strong>Summary of Services; Acceptance Criteria</strong>:</li>
        </ol>
        <p>&nbsp;</p>
        <p>Company will provide Customer access to Company&rsquo;s workout programming and music syncing software for
          utilization at Customer&rsquo;s Facility. Except as otherwise set forth herein, each Subscription shall be
          utilized by one (1) Facility and the Authorized Users located at that Facility.&nbsp;</p>
        <p>&nbsp;</p>
        <ol start="2">
          <li><strong>Facility </strong><strong>Subscription Pricing</strong>: The Subscription fee shall be a monthly
            fee, payable per each Facility utilizing the Services and the Software. The fees payable for each Facility
            shall be as follows:
          </li>
        </ol>
        <p>&nbsp;</p>
        <ul>
          <li>First Facility: $149.00 per month</li>
          <li>Second through Fifth Facility: $139.00 per month</li>
          <li>Sixth through Ninth Facility: $129.00 per month</li>
          <li>Tenth and additional Facilities: contact Company for reduced pricing.</li>
        </ul>
        <p>&nbsp;</p>
        <ol start="3">
          <li><strong>Personal Trainer Subscription Pricing</strong>: In the event that Customer does not own or operate
            a Facility and would like to purchase a Subscription limited to one (1) individual and the trainees of said
            individual (i.e. the Authorized Users shall be limited to one (1) personal trainer and the clients of said
            personal trainer and the Subscription shall not be transferrable to other personal trainers or clients of
            other personal trainers), the individual Subscription fee shall be $49.00 per month.
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p><strong>&nbsp;</strong></p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p><strong>&nbsp;</strong></p>
        <p><strong><br/> </strong></p>
        <p><strong>SCHEDULE A-1</strong></p>
        <p><strong>&nbsp;</strong></p>
        <p><strong>Payment Authorization</strong></p>
        <p>&nbsp;</p>
        <p>I authorize the credit card(s) or applicable ACH information on file to be used to cover full payment unless
          another method of payment was discussed, and approved by Company.</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
      </md-tab>

    </md-tabs>
  </div>
</template>

<script>
export default {
  name: "LegalText"
}
</script>

<style>

.md-tab h1 {
  line-height: 1;
}

.md-tab *, *::after, *::before {
  padding: revert;
}

.tab-padding {
  padding: 30px!important;
}
li{
  list-style-type: none!important;
}
</style>
