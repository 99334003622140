<template>
    <div class="component-wrap">
            <div style="display: flex;align-items: center;text-align: left;width: 100%;gap: 15px;">
                <div style="flex:70" class="figtree-medium-white-22px" >
                    Users
                </div>
                <div class="content-header__actions" style="flex:20;text-align: -webkit-right;">
                    <md-field class="search-box" style="padding: 0;">
                        <label style="font-size: 12px;" class=""><md-icon style="font-size: 20px!important;padding: 0;">search</md-icon>Search for Users by Name</label>
                        <md-input style="padding-top: 5px;font-size: 15px;"
                                v-model="filterUserString" 
                                >
                        </md-input>
                    </md-field>
                </div>
            </div>

        <AppSpinnerComponent
                v-if="loadingSpinner"
                :loading="loadingSpinner"/>

        <AppEmptyStateComponent
            v-if="showNoResults"
            message="There are no results."/>

        <div class="admins-list">
            <SingleStoreUserBlock
                v-for="(user, index) in filteredUsers"
                :key="index + '_invited_user'"
                @editUser="openEditUserDialog(user)"
                :userData="user"/>
        </div>

        <UsersEditDialog
            :showDialog.sync="showDialog"
            :key="dialogKey"
            :user-prop="userToEdit"
            :allRoles="allRoles"
            @updateCurrentUser="updateUser"/>

<!--        <UsersDeleteDialog-->
<!--            :showDialog.sync="showDeleteDialog"-->
<!--            :key="dialogDeleteKey"-->
<!--            :userToDelete="userToDelete"-->
<!--            @deleteCurrentUser="removeAdmin"/>-->

    </div>
</template>

<script>
    import UsersEditDialog from "@/components/Users/UsersEditDialog.vue"
    // import UsersDeleteDialog from "@/components/Users/UsersDeleteDialog.vue"
    import SingleStoreUserBlock from "@/components/Store/SingleStoreUserBlock.vue"
    import {DeepFilterMixin} from "@/mixins/DeepFilterMixin"
    import UserService from "../../services/user.service";
    import RoleService from "../../services/role.service";

    export default {
        name: 'AdminDashboardUsers',
        mixins: [DeepFilterMixin],

        data: () => ({
            users: [],
            userToEdit: {},
            userToDelete: {},
            loadingSpinner: true,
            showDialog: false,
            showDeleteDialog: false,
            showNoResults: false,
            dialogKey: 0,
            dialogDeleteKey: 100,
            filterUserString: '',
            allRoles: []
        }),

        components: {
            UsersEditDialog,
            // UsersDeleteDialog,
            SingleStoreUserBlock
        },

        computed: {

            filteredUsers() {

                return this.users.filter(x => this.filterFunction(x, this.filterUserString));
            }
        },

        methods: {

            setPageName(){

                this.$store.dispatch('changePageName', { pageNameString: 'Users'});
            },
            // openDeleteUserDialog(id) {
            //     this.userToDelete = this.filteredUsers.find(x => x.id === id);
            //     this.dialogDeleteKey++;
            //     this.showDeleteDialog = true;
            // },
            openEditUserDialog(user) {

                this.userToEdit = user;
                this.dialogKey++;
                this.showDialog = true;
            },

            updateUser(user) {

                UserService.updateUser(user)
                    .then((res) => {
                        if(typeof res === "string" && res.includes("The email address is already in use by another user.")) {
                            this.$store.dispatch('showSnackbarMessage', {message: 'The email address is already in use by another user.', mode: 'fail'  });
                        }
                        else{
                            this.$store.dispatch('showSnackbarMessage', {message: 'The user was successfully updated.'});
                        }
                        this.getAllUsers();
                    })
                    .catch((err) => {

                        this.$store.dispatch('showSnackbarMessage', {
                            message: `${err.message}. Please, try again.`,
                            mode: 'fail'
                        });
                        this.getAllUsers();
                    });
            },

            getRoles(){

                RoleService.getAll()
                    .then(data => {

                        this.allRoles = data;
                    })
                    .catch((error) => {

                    })
            },

            getAllUsers(){

                UserService.getAll()
                    .then(data => {

                        this.users = data;
                        this.loadingSpinner = false;
                    })
                    .catch((error) => {

                        this.$store.dispatch('showSnackbarMessage', {message: 'Failed. Please, try again.', duration: 8000, mode: 'fail'});
                        this.loadingSpinner = false;
                        this.showNoResults = true;
                    })
            }
        },
        watch:{
            filterUserString(val){
                this.showNoResults = this.filteredUsers.length==0 && val!='' ? true :false;
            }
        },
        created() {

            this.getRoles();
            this.setPageName();
            this.getAllUsers();
        }
    }
</script>

<style lang="scss" scoped>

	.component-wrap {
		margin-bottom: 5rem;
	}

	.input-field.input-field--filter {
		padding-bottom: 0;
	}

    .admins-filter-cont{
        margin-top: 50px;
        display: flex;
        justify-content: space-between;

        .permissions-label{
            font-size: 34px;
            font-weight: bold;
            margin-top: auto;
            margin-bottom: auto;
        }

        .filter-input {
            width: 25%;
        }
    }

    .admins-label{
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .admin{
        display: flex;
        justify-content: space-between;
	    padding: 15px 32px;
	    background-color: #212121;
        margin-bottom: 3px;
        cursor: pointer;

        &:hover {
            background-color: #ef476f;
            width: 102%;
            margin-left: -10px;
        }

        .user-email{
            color: rgba(255, 255, 255, 0.7);
        }
    }

    .user-remove-icon{
        &:hover {
            background-color: #212121;
            border-radius: 50%;
            padding: 3px;
            cursor: pointer;
        }
    }
    .search-box{
        align-items: center;
        background-color: var(--licorice);
        display: flex;
        flex-direction: column;
        gap: 5px;
        justify-content: center;
        width: fit-content;
        font-size: 10px;
        @media only screen and (max-width: 481px) {
           width: 170px;

        };
    }
</style>