<template>
  <div class="frame-335" :style="`border-left-color: ${bluePrint.color ? bluePrint.color : randomColor};
                                  cursor: ${!isDeleted && !isArchived ? 'pointer' : 'unset'}`">
    <md-menu @click.stop class="lock-icon">
      <md-button md-menu-trigger class="md-icon-button tag-input__btn">
        <md-icon style="color: white">more_vert</md-icon>
      </md-button>

      <md-menu-content style="background-color: var(--onyx)!important;">
        <md-menu-item @click.stop="editBluePrint(bluePrint.id)" v-if="!isDeleted">
          <md-button class="figtree-medium-white-12px">Edit
            Blueprint
          </md-button>
        </md-menu-item>
        <md-menu-item @click.stop="deleteSegment(bluePrint.id)"
                      v-if="userRole < 5 && !isDeleted">
          <md-button class="figtree-medium-white-12px">Delete Blueprint</md-button>
        </md-menu-item>
        <md-menu-item @click.stop="archiveBlueprint(bluePrint.id)"
                       v-if="userRole < 5 && !isDeleted">
          <md-button class="figtree-medium-white-12px">{{ isArchived ? 'UnArchive' : 'Archive' }} Blueprint</md-button>
        </md-menu-item>
        <md-menu-item @click.stop="restoreBlueprint(bluePrint.id)"
                       v-if="userRole < 5 && isDeleted">
          <md-button class="figtree-medium-white-12px">Restore Blueprint</md-button>
        </md-menu-item>
      </md-menu-content>
    </md-menu>

    <div class="frame-339">
      <div class="card-info">
        <div class="card-title">
          <md-icon v-if="!bluePrint.is_shared" style="color: #B2B2B2;margin-left: 8px;width: 15px;height: 15px">lock_outlined</md-icon>
          <div class="text-over-flow blueprint-name figtree-medium-white-14px">{{ bluePrint.name }}</div>
        </div>
        <div class="card-playlist-count">
          <div v-if="bluePrint.playlists && bluePrint.playlists.length > 0 && !isDeleted" class="play-button-div" @click.stop="openPlaylistModal(bluePrint.id)">
            <img style="height: 12px" src="@/assets/images/play.png" alt="Vector"/>
          </div>
          <div v-if="bluePrint.playlists" class="address figtree-normal-lavender-12px">{{ bluePrint.playlists.length }}
            playlists
          </div>
        </div>
        <div class="card-exercise-count">
          <div v-if="bluePrint.sections" class="address-1 figtree-normal-nobel-12px">{{ bluePrint.sections.length }}
            Exercises
          </div>
        </div>
        <div class="card-section-count">
          <div class="section-1" v-for="(section, index) in bluePrint.sections" :key="index" :value="section.id">
            <div class="section-1-1 figtree-normal-nobel-9px">{{ section.title }}</div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import BluePrintService from '@/services/bluePrint.service';
import {mapGetters} from "vuex"; 

export default {
  name: "DashboardCardTemplate",

  props: {

    isArchived: {
      type: Boolean,
      default: false
    },

    bluePrint: {
      type: Object,
      default: {}
    },

    isDeleted: {
      type: Boolean,
      default: false
    },
    randomColor: {
      type: String,
      default: ''
    }
  },

  methods: {

    restoreBlueprint(id) {

      this.$emit('restoreCurrentBlueprint', id);
    },

    archiveBlueprint(id) {

      if (this.isArchived) {

        this.$emit('unArchiveBluePrint', id);
      } else {

        this.$emit('archiveBluePrint', id);
      }
    },

    deleteSegment(id) {

      let _this = this;

      this.$emit('tempRemoveBluePrint', id);

      this.$store.dispatch('showSnackbarMessage', {
        message: 'Last chance to undo the delete.',
        duration: 5000,
        mode: 'success',
        buttonText: 'Undo',
        type: 'bluePrint'
      });

      setTimeout(() => {
        _this.deleteOneElement(id).then(() => {

          this.$emit('setLoadingSpinner', false);
        });
      }, 5000);
    },

    async deleteOneElement(id) {

      await this.deleteIfUndoIsInactive(id);
    },

    deleteBluePrint(id) {

      BluePrintService.deleteBluePrint({id: id})
          .then(() => {

            this.$store.dispatch('showSnackbarMessage', {
              message: 'The Blueprint was successfully deleted.',
              duration: 3000,
              mode: 'success'
            });
          })
          .catch((err) => {

            this.$store.dispatch('showSnackbarMessage', {
              message: `${err.message}. Please, try again.`,
              mode: 'fail'
            });
          });
    },

    deleteIfUndoIsInactive(id) {

      if (!(this.$store.getters['bluePrints/getIsUndoActive'])) {

        this.deleteBluePrint(id);
      }

      this.$store.commit('bluePrints/setIsUndoActive', false);
    },

    editBluePrint(id) {

      this.$emit('editBluePrint', id);
    },

    addToFavorite() {

      this.$store.dispatch('showSnackbarMessage', {message: 'Added to favorite!'})
    },

    openURL(url) {

      // console.log(url);
      window.open(url, '_blank');
    },

    playSong(id, bluePrintName) {

      BluePrintService.getBluePrintMix(id)
          .then((res) => {

            this.$store.dispatch('audioPlayer/updateSongToPlayFromBluePrint', {url: res.url, name: bluePrintName});
          })
          .catch((err) => {

            this.$store.dispatch('showSnackbarMessage', {
              message: `Mix was not found for this Blueprint`,
              mode: 'fail'
            });
          });
    },

    openPlaylistModal(id) {

      this.$emit('showPlaylistModal', id);
    },

    shareBluePrint() {

      this.$store.dispatch('showSnackbarMessage', {message: 'Shared!'})
    },

    showModalTableBluePrintInfo(id) {

      this.$emit('showModalTableBluePrintInfo', id);
    }
  },

  computed: {

    ...mapGetters(
        {
          userRole: 'auth/currentUserRole',
        },
    ),
  },
}
</script>

<style lang="scss" scoped>
@include card();

#blueprint-processing {
  margin-right: 10px;
}

.lock-icon {
  position: absolute;
  right: 0;
  margin: 5px;
}

.frame-335 {
  align-items: flex-start;
  background-color: rgba(255, 255, 255, 0.102);
  border-radius: 5px;
  border-left: 5px solid;
  display: flex;
  height: 143px;
  padding: 5px 10px 10px 10px;
  position: relative;
}

.frame-339 {
  align-items: flex-start;
  display: flex;
  position: relative;
}

.more_vert_fill0_wght400_grad0_opsz24-3 {
  height: 26px;
  min-width: 24px;
}

.card-info {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 0 10px;
}

.card-title {
  align-items: center;
  display: flex;
  padding: 10px 0;
}

.blueprint-name {
  line-height: 16px;
  margin-top: -1px;
  white-space: nowrap;
  width: 100%;
  max-width: 200px;
}

.card-playlist-count {
  align-items: flex-start;
  display: flex;
  gap: 10px;
}

.address {
  letter-spacing: 0;
  line-height: 14px;
  margin-top: -1px;
  white-space: nowrap;
}

.card-section-count {
  height: 45px;
  align-items: flex-start;
  display: flex;
  gap: 4px;
  position: relative;
  flex-wrap: wrap;
  overflow: auto;
  width: 99%;
}

.section-1 {
  align-items: flex-start;
  background-color: rgba(61, 61, 61, 1);
  border-radius: 5px;
  display: flex;
  gap: 10px;
  padding: 3px 10px;
}

.section-1-1 {
  letter-spacing: 0;
  line-height: 14px;
  margin-top: -1px;
  white-space: nowrap;
}

.more_vert_fill0_wght400_grad0_opsz24-3 {
  height: 26px;
  min-width: 24px;
}

::v-deep .md-list.md-theme-default{
  background-color: var(--onyx)!important;
  padding: 0;
}

::v-deep .md-list-item-content{
  padding: 0;
}
.play-button-div{
  align-items: center;
  margin-left: 2px;
  margin-right: 8px;
  //background-color: #222222;
  opacity: 0.7;
  border-radius: 16px;
  box-shadow: 0 4px 12px -4px #19141466;
  display: flex;
  //height: 32px;
  justify-content: flex-end;
  //right: 9px;
  //min-width: 32px;
  //padding: 0 9.5px;
  //position: absolute;
  //top: 66px;
  cursor: pointer;
  z-index: 20;
}
</style>
