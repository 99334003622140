<template>
    <div class="component-wrap">

        <AppSpinnerComponent v-if="loadingSpinner" :loading="loadingSpinner"/>


        <div class="store-profile">
            <div class="store-profile__card store-profile__logo">
              <div class="profile-img flex-20">
                <img :src="currentStore.image_url"  alt="profile-card"/>
              </div>
              <div class="" style="flex: 40; display:flex;flex-direction: column;gap: 15px;text-align: left;">
                <div  class="figtree-bold-white-46px current-store-name">{{ currentStore.name }}</div>
                <div style="flex:70;display:flex;flex-direction: column;" class="figtree-medium-pink-swan-16px-2 card-more-info">
                <div  class="flex-20 mail-over-flow">
                  <md-tooltip class="md-theme-light" md-direction="top">{{currentStore.email ? currentStore.email : 'email not set'}}</md-tooltip>
                  <span>                
                    <img class="profile-data-icons" src="@/assets/images/mail-fill0-wght400-grad0-opsz48-1@2x.png"  alt="profile-card"/>
                    {{currentStore.email ? currentStore.email : 'email not set'}}
                  </span>  
                </div>
                <div  class="flex-20">
                  <span>                
                    <img class="profile-data-icons" src="@/assets/images/phone-in-talk-fill0-wght400-grad0-opsz48-1@2x.png"  alt="profile-card"/>
                  </span>  
                  + {{currentStore.phone}}
                </div>
                <div  class="flex-20">
                  <span>                
                    <img class="profile-data-icons" src="@/assets/images/pin-drop-fill0-wght400-grad0-opsz48-1@2x.png"  alt="profile-card"/>
                  </span>  
                  {{currentStore.street}}, {{currentStore.suite}} ,{{currentStore.city}}, {{currentStore.zip}}
                </div>
                <div  class="flex-20 carde-code"> Code: {{ currentStore.code }}</div>



                </div>

              </div>
              <div style="flex:15" class="empty-flex"></div>
              <div class="flex-20 figtree-medium-pink-swan-16px-2 card-more-info" style="margin-top: 92px;" >
                <!-- <span>                
                    <img class="profile-data-icons" src="@/assets/images/screen-share-fill0-wght400-grad0-opsz48-1@2x.png"  alt="profile-card"/>
                  </span>   -->
                Code: {{ currentStore.code }}
               </div>

            </div>

<!--        <AppHeaderComponent-->
<!--            v-if="currentStore.accessRequests && currentStore.accessRequests.length > 0"-->
<!--            headline="Access Requests"-->
<!--            class="content-header&#45;&#45;medium"/>-->

<!--        <div v-if="currentStore.accessRequests && currentStore.accessRequests.length > 0" class="requests-list">-->
<!--            -->
<!--            <SingleStoreAccessRequestBlock-->
<!--                v-for="(accessRequest, index) in currentStore.accessRequests"-->
<!--                :key="index + '_access_request'"-->
<!--                :requestData="accessRequest"-->
<!--                @rejected="rejectRequest"-->
<!--                @accepted="acceptRequest"/>-->
<!--        </div>-->

<!--        <AppHeaderComponent headline="Users" class="content-header&#45;&#45;medium"/>-->

      <div style="flex:10;width: 100%;text-align: left;">
        <div class="flex-div">
          <div class="figtree-medium-white-20px user-header">
            Users
          </div>
  
          <div>
            <md-button class="md-primary md-raised dialog__btn md-theme-light figtree-normal-white-16px"
                       @click="showAddUserDialog = true">
                    <span>                
                      <img class="profile-data-icons" src="@/assets/images/person-add-fill1-wght400-grad0-opsz24-1@2x.png"  alt="profile-card"/>
                  </span>  
                    Add New User
            </md-button>
          </div>
        </div>
        <AppEmptyStateComponent message="There are no users yet." v-if="!adminUsers.length"/>
    
        <div style="flex:50;width: 100%;text-align: left;">
            <SingleStoreUserBlock
                v-for="(user, index) in adminUsers"
                :key="index + '_invited_user'"
                @editUser="openEditUserDialog(user)"
                :userData="user"/>
        </div>
      </div>
    </div>


        <UsersEditDialog
                :showDialog.sync="showDialog"
                :key="dialogKey"
                :user-prop="userToEdit"
                :allRoles="allRoles"
                @updateCurrentUser="updateUser"/>

      <div>
        <md-dialog :md-active.sync="showAddUserDialog" :md-click-outside-to-close="true">
          <md-dialog-title class="dialog-title">Add User</md-dialog-title>

              <md-dialog-prompt>Enter the email of the user to add to the facility
              </md-dialog-prompt>

            <md-field class="input" style="margin-top: 15px;">
              <label class="label figtree-normal-white-12px">Email</label>
              <md-input  style="width:100%;" class="figtree-normal-white-16px-2"
                    v-model="invitedUserEmail" 
                    required 
                   >
              </md-input>
            </md-field>

          <md-dialog-actions>
            <md-button class="dialog__btn dialog__btn--danger"
                       @click="showAddUserDialog = false">
              Cancel
            </md-button>
            <md-button class='md-primary md-raised dialog__btn md-theme-light'
                       :disabled="$v.invitedUserEmail.$invalid"    @click='addUser'>
              Add
            </md-button>
          </md-dialog-actions>
        </md-dialog>
      </div>

    </div>
</template>

<script>
    import UsersEditDialog from "@/components/Users/UsersEditDialog.vue"
    import SingleStoreUserBlock from "@/components/Store/SingleStoreUserBlock.vue"
    import SingleStoreAccessRequestBlock from "@/components/Store/SingleStoreAccessRequestBlock.vue"
    import StoreService from "../../services/store.service";
    import UserService from "../../services/user.service";
    import $axios from "../../plugins/axios";
    import {email, required} from 'vuelidate/lib/validators';

    export default {
        name: 'StoreDashboardSingleStore',

        data: () => ({
          currentStore: {},
          adminUsers: [],
          userToEdit: {},
          dialogKey: 0,
          allRoles: [],
          showDialog: false,
          showAddUserDialog: false,
          loadingSpinner: false,
          invitedUserEmail: null
        }),
        validations: {
              invitedUserEmail: {required, email}
        },
        components: {
            UsersEditDialog,
            SingleStoreUserBlock,
            SingleStoreAccessRequestBlock
        },

        methods: {

            acceptRequest() {

                this.removeRequestFromArray();
            },

            rejectRequest() {

                this.removeRequestFromArray();
            },

            removeRequestFromArray(index) {

                this.currentStore.accessRequests.splice(index, 1);
            },

            setPageName() {

                const currentPageName = this.currentStore.name;
                this.$store.dispatch('changePageName', { pageNameString: currentPageName});
            },

            openEditUserDialog(user) {

                this.userToEdit = user;
                this.dialogKey++;
                this.showDialog = true;
            },

            removeAdmin(id) {

                this.$store.dispatch('users/deleteSingleUser', {userId: id})
                    .then(()=> {

                        this.getUsers();
                        this.$store.dispatch('showSnackbarMessage', {
                            message: 'The user was successfully deleted.', 
                            mode: 'success'
                        });
                    })
                    .catch((err) => {

                        this.$store.dispatch('showSnackbarMessage', 
                            {message: `${err.message}. Please, try again.`,
                            mode: 'fail'
                        });
                    });
            },

            getUsers(id) {

                StoreService.getAllUsersByStore(id)
                    .then((res) => {

                        this.adminUsers = res;
                    })
                    .catch(() => {

                        this.$store.dispatch('showSnackbarMessage', {
                            message: 'Failed. Please, try again.',
                            duration: 8000,
                            mode: 'fail'
                        });
                    });
            },

            updateUser(user) {

              UserService.updateUser(user)
                    .then((res) => {
                        if(typeof res === "string" && res.includes("The email address is already in use by another user.")) {
                            this.$store.dispatch('showSnackbarMessage', {message: 'The email address is already in use by another user.', mode: 'fail'  });

                        }
                        else{
                            this.$store.dispatch('showSnackbarMessage', {message: 'The user was successfully updated.'});
                        }
                        this.getUsers(this.$route.params.id);
                    })
                    .catch((err) => {

                        this.$store.dispatch('showSnackbarMessage', {
                            message: `${err.message}. Please, try again.`,
                            mode: 'fail'
                        });
                        this.getUsers(this.$route.params.id);
                    });
            },

          async addUser() {
            const request = {"email": this.invitedUserEmail};

            await StoreService.addUser(this.$route.params.id, request).then(() => {
              this.getUsers(this.$route.params.id)
              this.$store.dispatch('showSnackbarMessage', {
                message: 'User is added successfully.',
                duration: 8000,
                mode: 'success'
              });
              this.showAddUserDialog = false;
            }).catch((err) => {
              this.$store.dispatch('showSnackbarMessage', {
                message: err.response.data,
                mode: 'fail'
              });
            });
          },

            getTheStore(id) {

                StoreService.getSingleStore(id)
                    .then((res) => {

                        this.currentStore = res;
                        this.currentStore['accessRequests'] = [{userName: 'asdasdad'}];
                    })
                    .catch(() => {

                        this.$store.dispatch('showSnackbarMessage', {
                            message: 'Failed. Please, try again.',
                            duration: 8000,
                            mode: 'fail'
                        });
                    });
            },
        },

        created() {

            const storeId = this.$route.params.id;
            this.getTheStore(storeId)
            this.getUsers(storeId)
            this.setPageName();
        }
    }
</script>

<style lang="scss" scoped>
@include dialogSettings();
.flex-div {
  margin-top: 50px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;

  .user-header {
    flex: 60;
  }
}

.dialog-title {
  text-align: left;
  padding: 0;
}

.store-profile {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 15px;

  &__card {
    display: flex;
    box-shadow: $base-shadow;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: linear-gradient(180deg, rgba(178, 178, 178, 0) -48.79%, rgba(25, 20, 20, 0.4) 108.47%);
    height: 364px;
    gap: 15px;
    padding: 0 10px 0 10px;

    @media only screen and (max-width: 834px) {
      height: 250px;

    };

  }

  &__logo {
    .profile-img  img {
      height: 206px;
      width: 206px;
      min-width: 206px;

      border-radius: 50%;
      @media only screen and (max-width: 834px) {
        height: 130px;
        width: 130px;
        min-width: 130px;
    };
    @media only screen and (max-width: 600px) {
        height: 103px;
        width: 103px;
        min-width: 103px;

    };
    @media only screen and (max-width: 481px) {
        height: 90px;
        width:90px;
        min-width:90px;

    };
    }
  }

  &__data {
    padding: 24px 16px;
    line-height: 1;
    text-align: left;
  }

  &__label {
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 16px;
  }

  &__info {
    font-size: 14px;
    font-weight: 300;
    margin: 0 0 8px;
  }
}
.flex-20{
  flex:20;
}
.profile-data-icons{
      width: 22px;
      height: 22px;
      max-width: 22px;
      max-height: 22px;
      @media only screen and (max-width: 834px) {
        width: 18px;
        height: 18px;
        max-width: 18px;
        max-height: 18px;
      };
}
.current-store-name{
  flex:30;
  line-height: 40px;
  @media only screen and (max-width: 834px) {
      font-size:35px ;
  };
  @media only screen and (max-width: 481px) {
      font-size:25px ;
  };
}
.card-more-info{
  @media only screen and (max-width: 834px) {
      font-size:var(--font-size-s); ;
  };
  @media only screen and (max-width: 481px) {
    display: none;

  };
}
.carde-code{
  margin-top: 10px;
  display: none;
  @media only screen and (max-width: 481px) {
    display: block;

  };
}
.empty-flex{
  @media only screen and (max-width: 834px) {
      // flex:5!important;
      display: none;
  };
}
.mail-over-flow {
  @media only screen and (max-width: 481px) {
      max-width: 200px;
  };
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
