<template>
    <div class="component-wrap">
        <AppHeaderComponent headline="User">

        </AppHeaderComponent>

        <UserForm v-if="currentUser"
                :user-prop.sync="currentUser"
                @updateCurrentUser="editUser"
        />

    </div>
</template>

<script>
    import {DeepFilterMixin} from "@/mixins/DeepFilterMixin"
    import UserForm from '@/components/Users/UserForm.vue'
    import UserService from "../../services/user.service";

    export default {
        name: 'UserPage',
        mixins: [DeepFilterMixin],
        data: () => ({
            currentUser: null
        }),
        components: {
            UserForm
        },
        computed: {
        },
        watch:{
        },
        methods: {

            setPageName(){

                this.$store.dispatch('changePageName', { pageNameString: 'Users'});
            },

            editUser(user) {

                UserService.updateUser(user)
                    .then((res) => {
                        if(typeof res === "string" && res.includes("The email address is already in use by another user.")) {
                            this.$store.dispatch('showSnackbarMessage', {message: 'The email address is already in use by another user.', mode: 'fail'  });
                        }
                        else{
                            this.$store.dispatch('showSnackbarMessage', {message: 'The user was successfully updated.'});
                        }
                    })
                    .catch((err) => {

                        this.$store.dispatch('showSnackbarMessage', {
                            message: `${err.message}. Please, try again.`,
                            mode: 'fail'
                        });
                    });
            },

            getCurrentUser(){

                if (this.$route.params.id) {

                    return UserService.getOne(this.$route.params.id)
                        .then((data) => {

                            this.currentUser = data;
                        })
                        .catch((err) => {

                            this.$store.dispatch('showSnackbarMessage',
                                {
                                    message: `${err.message}. Please, try again.`,
                                    mode: 'fail'
                                });
                        });
                }
            },
        },

        created() {

            this.setPageName();
            this.getCurrentUser();
        },
    }
</script>

<style lang="scss" scoped>
    .input-field.input-field--filter {
        padding-bottom: 0;
    }

    .admins-filter-cont{
        margin-top: 50px;
        display: flex;
        justify-content: space-between;

        .permissions-label{
            font-size: 34px;
            font-weight: bold;
            margin-top: auto;
            margin-bottom: auto;
        }

        .filter-input {
            width: 25%;
        }
    }

    .admins-label{
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .admin{
        display: flex;
        justify-content: space-between;
	    padding: 15px 32px;
	    background-color: #212121;
        margin-bottom: 3px;
        cursor: pointer;

        &:hover {
            background-color: #ef476f;
            width: 102%;
            margin-left: -10px;
        }

        .user-email{
            color: rgba(255, 255, 255, 0.7);
        }
    }

    .user-remove-icon{
        &:hover {
            background-color: #212121;
            border-radius: 50%;
            padding: 3px;
            cursor: pointer;
        }
    }
</style>