<template>
	<div class="page-container">
		<md-app md-waterfall md-mode="fixed" v-if="currentUser">

			<md-app-toolbar class="md-primary">

				<md-button
					class="md-icon-button navigation-btn focus-btn"
					v-if="!menuVisible" @click="menuVisible = !menuVisible">
					<md-icon style=" color: white;">menu</md-icon>
				</md-button>

				<div class="app-content toolbar-content">
					<img
						srcset="@/assets/images/logo-after-logged-in.png 1x, @/assets/images/logo-after-logged-in.png 2x"
						v-if="!menuVisible" @click="goToHome()" alt="Logo image"
						class="app-logo">

					<div class="search md-content "
						 v-if="!currentUser.account.is_trainer"
						 :class="{activeSearch: isSearchVisibleOnMobile}">
						<md-field md-clearable
								  @md-clear="clearResult()">
							<md-icon class="search-icon">search</md-icon>
							<md-input v-model="searchInput"
									  @keyup="goToSearch()"></md-input>
						</md-field>
					</div>

					<div class="toolbar-menu">
						<md-icon class="icon-btn md-primary u-color-text-primary search-mobile-icon"
								 v-if="!currentUser.account.is_trainer"
								 @click.native="isSearchVisibleOnMobile = !isSearchVisibleOnMobile">search
						</md-icon>

						<span class="md-title" id="page-title" style="color:#979797">{{ pageName }}</span>

						<!--                        <md-icon class="icon-btn md-primary u-color-text-primary">notifications_none</md-icon>-->

						<router-link
							to="/account-info"
							id="account-icon"
						>
							<md-icon class="icon-btn md-primary u-color-text-primary">person_outline</md-icon>
						</router-link>

<!--						<md-menu md-size="medium" md-align-trigger>-->
<!--							&lt;!&ndash;							<md-button md-menu-trigger md-theme="selection-black">Account/Person Icon</md-button>&ndash;&gt;-->

<!--							<md-icon md-menu-trigger class="icon-btn md-primary u-color-text-primary">person_outline-->
<!--							</md-icon>-->

<!--							<md-menu-content>-->
<!--								<md-menu-item v-if="userRole > 1 && !currentUser.account.is_trainer">-->
<!--									<router-link-->

<!--										id="account"-->
<!--										to="/account"-->
<!--										tag="md-list-item"-->
<!--										class="menu-links"-->
<!--										@click.native="menuVisible = !menuVisible">-->
<!--										<md-icon>account_circle</md-icon>-->
<!--										<span class="md-list-item-text"> Account</span>-->
<!--									</router-link>-->
<!--								</md-menu-item>-->

<!--								<md-menu-item v-if="userRole === 1 && !currentUser.account.is_trainer">-->
<!--									<router-link-->

<!--										id="accounts"-->
<!--										to="/accounts"-->
<!--										tag="md-list-item"-->
<!--										class="menu-links"-->
<!--										@click.native="menuVisible = !menuVisible">-->
<!--										<md-icon>account_circle</md-icon>-->
<!--										<span class="md-list-item-text"> Accounts</span>-->
<!--									</router-link>-->
<!--								</md-menu-item>-->

<!--								<md-menu-item>-->
<!--									<router-link-->
<!--										id="stores"-->
<!--										to="/stores"-->
<!--										tag="md-list-item"-->
<!--										class="menu-links"-->
<!--										v-if="userRole < 4 && !currentUser.account.is_trainer"-->
<!--										@click.native="menuVisible = !menuVisible">-->
<!--										<md-icon>store_mall_directory</md-icon>-->
<!--										<span class="md-list-item-text">Facility</span>-->
<!--									</router-link>-->
<!--								</md-menu-item>-->

<!--								<md-menu-item>-->
<!--									<router-link-->
<!--										to="/cards"-->
<!--										id="cards"-->
<!--										tag="md-list-item"-->
<!--										class="menu-links"-->
<!--										v-if="userRole < 3 && !currentUser.account.is_trainer"-->
<!--										@click.native="menuVisible = !menuVisible">-->
<!--										<md-icon>credit_card</md-icon>-->
<!--										<span class="md-list-item-text">Cards</span>-->
<!--									</router-link>-->
<!--								</md-menu-item>-->

<!--								<md-menu-item>-->
<!--									<router-link-->
<!--										to="/subscriptions"-->
<!--										id="subscriptions"-->
<!--										tag="md-list-item"-->
<!--										class="menu-links"-->
<!--										v-if="userRole < 5"-->
<!--										@click.native="menuVisible = !menuVisible">-->
<!--										<md-icon>subscriptions</md-icon>-->
<!--										<span class="md-list-item-text">Subscription</span>-->
<!--									</router-link>-->
<!--								</md-menu-item>-->

<!--							</md-menu-content>-->
<!--						</md-menu>-->

						<md-icon class="icon-btn md-primary u-color-text-primary" @click.native="logOut()">
							<md-tooltip class="md-theme-light" md-direction="bottom">Log Out From Here</md-tooltip>
							exit_to_app
						</md-icon>
					</div>

				</div>
			</md-app-toolbar>

<!--			<md-app-toolbar class="md-primary tour-guide-ribbon" v-if="showTourGuideRibbon">-->
<!--				<div id="tour-ribbon">-->
<!--					<span> Learn how to use our website. </span>-->
<!--					<a @click="startTourGuide">  Click through Tutorial <u> here</u>. </a>-->
<!--				</div>-->
<!--			</md-app-toolbar>-->

			<md-app-drawer
				:aria-hidden="!menuVisible"
				:md-active.sync="menuVisible"
				class="md-theme-light drawer-container">

				<md-toolbar class="navigation-toolbar md-primary" md-elevation="0">

					<md-button
						:tabindex="menuVisible ? '0' : '-1'"
						class="md-icon-button focus-btn"
						@click="menuVisible = !menuVisible">
						<md-icon style="color: #B6B6B6">menu</md-icon>
					</md-button>

					<img
						srcset="@/assets/images/logo-after-logged-in.png 1x, @/assets/images/logo-after-logged-in.png 2x"
						alt="Logo text"
						class="app-logo">
				</md-toolbar>

				<md-list class="md-theme-light">

					<!--					<router-link-->
					<!--						id="account-person"-->
					<!--						to="/dashboard"-->
					<!--						tag="md-list-item"-->
					<!--						class="menu-links"-->
					<!--						@click.native="menuVisible = !menuVisible">-->
					<!--						<md-icon>home</md-icon>-->
					<!--						<span class="md-list-item-text">Account</span>-->
					<!--					</router-link>-->
					<md-list-item class="md-list-item-container" md-expand :md-expanded.sync="expandNews">
						<router-link
							id="dashboard"
							to="/dashboard"
							tag="md-list-item"
							class="menu-links"
							@click.native="menuVisible = !menuVisible">
							<md-icon>home</md-icon>
							<span class="md-list-item-text">Dashboard</span>
						</router-link>

          				<md-list slot="md-expand">
          				  <md-list-item class="md-inset">
							<router-link
							id="deleted-blueprints"
							:to="'/deleted-blueprints/' + userSelectedStore"
							tag="md-list-item"
							class="menu-links"
							@click.native="menuVisible = !menuVisible">
							<img class="union" src="@/assets/images/Restorearchived.png" alt="fullscreen"/>
							<span class="md-list-item-text">Deleted Blueprints</span>
						</router-link>

						</md-list-item>
          				  	<md-list-item class="md-inset">
								<router-link
									id="dashboard"
									:to="'/archived-blueprints/' + userSelectedStore"
									tag="md-list-item"
									class="menu-links"
									@click.native="menuVisible = !menuVisible">
										<img class="union" src="@/assets/images/Restorearchived.png" alt="fullscreen"/>
										<span class="md-list-item-text">Archived Blueprints</span>
								</router-link>
							</md-list-item>
          				</md-list>
        			</md-list-item>
					<!-- <md-list class="md-theme-light">

						<router-link
							id="dashboard"
							to="/dashboard"
							tag="md-list-item"
							class="menu-links"
							@click.native="menuVisible = !menuVisible">
							<md-icon>home</md-icon>
							<span class="md-list-item-text">Dashboard</span>
						</router-link>
					</md-list> -->

					<!--                    <router-link-->
					<!--                        to="/material"-->
					<!--                        tag="md-list-item"-->
					<!--                        class="menu-links"-->
					<!--                        @click.native="menuVisible = !menuVisible">-->

					<!--                        <md-icon>subscriptions</md-icon>-->
					<!--                        <span class="md-list-item-text">Library</span>-->
					<!--                    </router-link>-->

					<!--                    <router-link-->
					<!--                        to="/material"-->
					<!--                        tag="md-list-item"-->
					<!--                        class="menu-links"-->
					<!--                        @click.native="menuVisible = !menuVisible">-->
					<!--                        <md-icon>person</md-icon>-->
					<!--                        <span class="md-list-item-text">User Management</span>-->
					<!--                    </router-link>-->

<!--					<router-link-->
<!--						to="/invitations"-->
<!--						id="invitations"-->
<!--						tag="md-list-item"-->
<!--						class="menu-links"-->
<!--						v-if="userRole < 5 && !currentUser.account.is_trainer"-->
<!--						@click.native="menuVisible = !menuVisible">-->
<!--						<md-icon>person_add</md-icon>-->
<!--						<span class="md-list-item-text">Invitations</span>-->
<!--					</router-link>-->

					<router-link
						to="/users"
						id="users"
						tag="md-list-item"
						class="menu-links"
						v-if="userRole < 4 "
						@click.native="menuVisible = !menuVisible">
						<md-icon>person</md-icon>
						<span class="md-list-item-text">User Management</span>
					</router-link>

					<router-link
						to="/myyoutube"
						id="how-to"
						tag="md-list-item"
						class="menu-links"
						@click.native="menuVisible = !menuVisible">
						<md-icon>slideshow</md-icon>
						<span class="md-list-item-text">How to / FAQ</span>
					</router-link>

<!--					<router-link-->
<!--						to="/marketing"-->
<!--						id="marketing"-->
<!--						tag="md-list-item"-->
<!--						class="menu-links"-->
<!--						v-if="userRole < 2 && !currentUser.account.is_trainer"-->
<!--						@click.native="menuVisible = !menuVisible">-->
<!--						<md-icon>contactless</md-icon>-->
<!--						<span class="md-list-item-text">Marketing</span>-->
<!--					</router-link>-->

					<router-link
						to="/myshopify"
						id="myshopify"
						tag="md-list-item"
						class="menu-links"
						@click.native="menuVisible = !menuVisible">
						<md-icon>store</md-icon>
						<span class="md-list-item-text">About Us/Merch</span>
					</router-link>

<!--					<router-link-->
<!--						v-if="!currentUser.account.is_trainer"-->
<!--						to="/about"-->
<!--						id="about"-->
<!--						tag="md-list-item"-->
<!--						class="menu-links"-->
<!--						@click.native="menuVisible = !menuVisible">-->
<!--						<md-icon>info</md-icon>-->
<!--						<span class="md-list-item-text">About Reflex Radio</span>-->
<!--					</router-link>-->

					<router-link
						to="/contact"
						id="contact"
						tag="md-list-item"
						class="menu-links"
						@click.native="menuVisible = !menuVisible">
						<md-icon>import_contacts</md-icon>
						<span class="md-list-item-text">Contact Us</span>
					</router-link>

<!--					<router-link-->
<!--						v-if="!currentUser.account.is_trainer"-->
<!--						to="/news"-->
<!--						id="news"-->
<!--						tag="md-list-item"-->
<!--						class="menu-links"-->
<!--						@click.native="menuVisible = !menuVisible">-->
<!--						<md-icon>wysiwyg</md-icon>-->
<!--						<span class="md-list-item-text">News</span>-->
<!--					</router-link>-->

					<router-link
						to="/legal"
						id="legal"
						tag="md-list-item"
						class="menu-links"
						@click.native="menuVisible = !menuVisible">
						<md-icon>gavel</md-icon>
						<span class="md-list-item-text">Legal Disclosures</span>
					</router-link>

					<router-link
						to="/songs"
						id="songs"
						tag="md-list-item"
						class="menu-links"
						v-if="userRole < 2"
						@click.native="menuVisible = !menuVisible">
						<md-icon>library_music</md-icon>
						<span class="md-list-item-text">Songs</span>
					</router-link>

					<router-link
						to="/subscriptions"
						id="subscriptions"
						tag="md-list-item"
						class="menu-links"
						v-if="userRole == 1 || isFacilityOwner == true || isIndividualTrainer == true  || userRole == 3"
						@click.native="menuVisible = !menuVisible">
						<md-icon>subscriptions</md-icon>
						<span class="md-list-item-text">Subscriptions</span>
					</router-link>

					<router-link
						to="/reports"
						id="reports"
						tag="md-list-item"
						class="menu-links"
						v-if="userRole < 2"
						@click.native="menuVisible = !menuVisible">
						<md-icon>assessment</md-icon>
						<span class="md-list-item-text">Reports</span>
					</router-link>
					<router-link
						to="/music-engine-settings"
						id="settings"
						tag="md-list-item"
						class="menu-links"
						v-if="userRole < 2"
						@click.native="menuVisible = !menuVisible">
						<md-icon>settings</md-icon>
						<span class="md-list-item-text">Music Engine Settings</span>
					</router-link>

<!--					<router-link-->
<!--						v-if="userRole == 1"-->
<!--						to="/classes"-->
<!--						id="classes"-->
<!--						tag="md-list-item"-->
<!--						class="menu-links"-->
<!--						@click.native="menuVisible = !menuVisible">-->
<!--						<md-icon>fitness_center</md-icon>-->
<!--						<span class="md-list-item-text">Classes</span>-->
<!--					</router-link>-->

<!--					<router-link-->
<!--						to="/interval-types"-->
<!--						id="interval-types"-->
<!--						tag="md-list-item"-->
<!--						class="menu-links"-->
<!--						v-if="userRole == 1"-->
<!--						@click.native="menuVisible = !menuVisible">-->
<!--						<md-icon>forward_5</md-icon>-->
<!--						<span class="md-list-item-text">Movement Types</span>-->
<!--					</router-link>-->

<!--					<router-link-->
<!--						v-if="userRole == 1"-->
<!--						to="/rooms"-->
<!--						id="rooms"-->
<!--						tag="md-list-item"-->
<!--						class="menu-links"-->
<!--						@click.native="menuVisible = !menuVisible">-->
<!--						<md-icon>meeting_room</md-icon>-->
<!--						<span class="md-list-item-text">Rooms</span>-->
<!--					</router-link>-->

					<router-link
						to="/genres"
						id="genres"
						tag="md-list-item"
						class="menu-links"
						v-if="userRole < 2"
						@click.native="menuVisible = !menuVisible">
						<md-icon>queue_music</md-icon>
						<span class="md-list-item-text">Music</span>
					</router-link>

				</md-list>
			</md-app-drawer>

			<md-app-content>
				<div class="app-content">

					<slot/>
				</div>
			</md-app-content>

		</md-app>

		<div class="vld-parent">

			<loading-overlay :active.sync="loadingOverlay"
							 :can-cancel="true"
							 :is-full-page="true"
							 :color="'#A156A0'"
							 :backgroundColor="'linear-gradient(to right, #420e38, #380e30, #380e30, #2f0e29, #260d21, #1e091a, #1d071a, #1b0519, #1a0319, #1e0627, #1e0830, #1c0a39)'"
							 :loader="'dots'"
							 :opacity="1"
			>
				<AnimatedLogoPreloader></AnimatedLogoPreloader>
				<AppSpinnerComponent
					:color="'#A156A0'"
					:loading="true"
					:size="'20px'"
					:type="'beat'"
				/>
			</loading-overlay>
		</div>

		<div v-if="isIntervalPlaying == false" class="audio-player">
			<AppleAudioPlayer v-if="currentUser.current_music_streaming_service == 1" :showPlayer="!isIntervalPlaying" />
			<SpotifyAudioPlayer  v-else-if="currentUser.current_music_streaming_service == 2" :showPlayer="!isIntervalPlaying"/>

		</div>

    <md-button class="md-fab md-fab-bottom-right" v-if="!showFeedbackDialog" title="Send Feedback"
               @click.native="openFeedBackDialog()">
      <md-icon class="md-theme-light">feedback</md-icon>
    </md-button>

    <feedback-dialog
        v-if="showFeedbackDialog"
        :showDialog.sync="showFeedbackDialog"
        :key="FeedbackDialogKey"
    />

	</div>
</template>

<script>
	import {mapGetters} from 'vuex';
	import LoadingOverlay from 'vue-loading-overlay';
	import 'vue-loading-overlay/dist/vue-loading.css';
	import AudioPlayer from '../../components/AudioPlayer/AudioPlayer';
	import AppleAudioPlayer from '../../components/AudioPlayer/AppleAudioPlayer';
	import SpotifyAudioPlayer from '../../components/AudioPlayer/SpotifyAudioPlayer';

  import AnimatedLogoPreloader from '@/components/Common/AnimatedLogoPreloader';
  import FeedbackDialog from "./FeedbackDialog";

	export default {
		name: 'TheLayoutWrap',
		components: {
      FeedbackDialog,
			AudioPlayer,
			AppleAudioPlayer,
			LoadingOverlay,
			AnimatedLogoPreloader,
			SpotifyAudioPlayer
		},
		data: () => ({
			expandSingle: false,
			expandNews: false,
			isSearchVisibleOnMobile: true,
			menuVisible: false,
			value: null,
			allSearchResults: {
				accounts: [],
				users: [],
			},
			results: [],
			checkInterval: null,
			searchInput: '',
			isShowResults: false,
      isFirstStepOfTourGuide: true,
			FeedbackDialogKey:0,
      showFeedbackDialog :false,
		}),
		methods: {
			openFeedBackDialog() {
            this.FeedbackDialogKey++;
            this.showFeedbackDialog = !this.showFeedbackDialog;
        },
			startTourGuide() {

				this.$store.dispatch('changeShowTourGuideRibbon', false);

				this.$store.dispatch('changeIsTourGuideActive', true);
				this.$tours['myTour'].start();
			},

			async goNextStepIfTourGuideActive() {

				await new Promise(resolve => setTimeout(resolve, 500));

				this.$nextTick(() => {

						this.$tours['myTour'].nextStep();
				})
			},

			goToHome() {

				this.$router.push('/dashboard');
			},

			logOut() {

				this.$store.dispatch('auth/signOut').then((res) => {

					this.$store.dispatch('audioPlayer/updateSongToPlayFromBluePrint', {song: null, name: null});
					this.music.stop()
					this.music.clearQueue();
					this.$store.dispatch('audioPlayer/resetPlayerData');
					this.$router.replace('/login');
				});
			},

			goToSearch() {

				this.getResults();

				if (!this.$route.path.includes('/search')) {

					this.$router.push('/search');
				}
			},

			getResults() {

				this.results = new Promise(resolve => {
					setTimeout(() => {

						this.fetchSearchResults();
						this.isShowResults = true;
					}, 500);
				});
			},

			getResultsForSearchbar() {

				return this.$store.dispatch('searches/getSearchResults', {data: this.searchInput}).then((res) => {

					this.allSearchResults = res;
				}).catch((err) => {

					this.$store.dispatch('showSnackbarMessage', {
						message: `${err.message}. Please, try again.`,
						mode: 'fail',
					});
				});
			},

			async fetchSearchResults() {

				await this.getResultsForSearchbar();
			},

			clearResult() {

				this.$router.go(-1);
			},

        clickOnMenuLink() {

            this.menuVisible = !this.menuVisible;

            if (this.showTourGuide) {

                this.$store.dispatch('changeOpenMenuForTourGuide', false);
            }
        },


        setMenuToVisible() {

            this.menuVisible = true
        },

        async showMenuNextTourStep() {

            await this.setMenuToVisible();

            if (this.isFirstStepOfTourGuide == false) {

                await this.goNextStepIfTourGuideActive();
                return;
            }

            this.isFirstStepOfTourGuide = false;
        }
		},

		computed: {
			...mapGetters(
				{
					userRole: 'auth/currentUserRole',
					loadingOverlay: 'getLoadingOverlay',
					pageName: 'getCurrentPageName',
					currentUser: 'auth/getCurrentUser',
          showTourGuide: 'getOpenMenuForTourGuide',
					songFile: 'audioPlayer/getSong',
					isIntervalPlaying: 'audioPlayer/getIsIntervalPlaying',
					showTourGuideActive: 'getIsTourGuideActive',
					showTourGuideRibbon: 'getShowTourGuideRibbon',
					userSelectedStore: 'auth/getSelectedStore',
				},
			),

			isIndividualTrainer() {

				return this.userRole == 2 && this.currentUser.account.is_trainer;
			},

			isFacilityOwner() {

				return this.userRole == 2 && this.currentUser.account.is_trainer == false;
			},
		},

    watch: {

        showTourGuide(newValue) {

            if (newValue) {

                this.showMenuNextTourStep();
            }
        }
    },

		created() {

      LogRocket.identify('THE_USER_ID_IN_YOUR_APP', {
        name: this.currentUser.first_name + ' ' + this.currentUser.last_name,
        email: this.currentUser.email,
      });

			this.$store.dispatch('changeLoadingOverlay', {loading: true});
			this.music= MusicKit.getInstance();

			setTimeout(() => {
				this.$store.dispatch('changeLoadingOverlay', {loading: false});
			}, 1900);
		},
	};
</script>

<style lang="scss" scoped>
	.md-app {
		min-height: 100vh;
		max-height: 100vh;
	}

	.md-drawer {
		width: 280px;
		max-width: calc(100vw - 125px);

		.md-list {
			margin-top: 24px;
			margin-left: 10px;
		}
	}

	.focus-btn {
		&:hover,
		&:focus,
		&:active {
			background: rgba($color-white, .3);
		}
	}

	.navigation-btn {
		position: absolute;
	}

	.md-app-content {
		padding: 16px 0;

		.app-content {
			margin-bottom: 100px;
		}
	}

	.app-content {
		width: $container-width;
		margin: 0 auto;
		// padding-left: 40px;
		// padding-right: 40px;

		@media only screen and (max-width: 1350px) {
			width: 98%;
			margin: 0 auto;
		};

		@media only screen and (max-width: 1280px) {
			width: 90%;
			margin: 0 auto;
		};

		@media only screen and (max-width: 960px) {
			width: 748px;
		};

		@media only screen and (max-width: 768px) {
			width: 580px;
		};

		@media only screen and (max-width: 600px) {
			width: 100%;
			padding: 1.6rem 2.4rem;
		};

		.app-logo {
			height: 35px;

			@media only screen and (max-width: 960px) {
				margin-left: 2.4rem;
			};

			@media only screen and (max-width: 1080px) {
				display: none;
			};

			@media only screen and (max-width: 1400px) {
				margin-left: 3.4rem;
			};
		}

		.search {
			background-color: #2C2C2C;
			color: #FFF;
			text-decoration: none!important;;
			border-radius: 20px;
			margin: 0;
			position: absolute;
			top: 50%;
			left: 45%;
			-ms-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
			height: 35px !important;
			vertical-align: middle;
			width: 400px;

			.search-icon {
				margin-left: 10px;
				color: #979797!important;
			}
		}

		.md-focused input {
			-webkit-text-fill-color: black;

			.md-icon.md-icon-font.md-theme-default {
				visibility: hidden;
			}
		}

		@media screen and (min-width: 768px) {
			.search-mobile-icon {
				display: none;
			}
		}

		@media screen and (min-width: 768px) {
			.search {
				max-width: 500px;
			}
		}

		@media screen and (max-width: 768px) {
			.search {
				max-width: 90%;
				top: 90px;
				left: 50%;
			}

			.activeSearch {
				display: none;
			}


		}


	}

	.toolbar-content {
		display: flex;
		align-items: center;
		height: 64px;
	}

	.toolbar-menu {
		margin-left: auto;

		.md-title {
			line-height: 3.6rem;


			@media only screen and (min-width: 1380px) {
				margin-right: 6.4rem;
			};

			@media only screen and (max-width: 1080px) {
				display: none;

			};

		}
	}

	.icon-btn {
		width: 36px;
		height: 36px;
		border-radius: 50%;
		background-color: #FFF;
		margin-left: 2.4rem;
		cursor: pointer;
		transition: all .1s ease-in;

		&:hover {
			box-shadow: 0 1rem 2.4rem rgba(#FFF, .3);
		}

		&:active {
			transform: translateY(2px);
		}
	}

	.drawer-container {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
		background-color: #212121!important;
		.md-theme-light {
			background-color: #212121;

			.md-icon {
				color: #B6B6B6;
			}

			.md-list-item-text {
				font-family: "Roboto";
				font-size: 14px;
				font-weight: bold;
				font-stretch: normal;
				font-style: normal;
				line-height: 2.07;
				letter-spacing: normal;
				color: #B6B6B6;
			}

		}

		.router-link-active {

			.md-list-item-text {
				color: white;
			}

			.md-icon {
				color: white;
			}
		}
	}

	::v-deep .md-list-item-button:focus {
		background: rgba(0, 0, 0, .12);
	}
	::v-deep .md-list-item-content .md-ripple{
			padding: 0!important;
			min-height: 40px;

	}
	::v-deep  .md-drawer .md-list{
			margin:  0;
			padding: 0;
	}
	::v-deep .md-list-expand .md-list.md-theme-default {
		background-color: transparent;
	}
	::v-deep .md-list-item .md-inset .md-list-item-content .md-list-item-text{
		font-family: "Roboto";
		font-size: 13px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		letter-spacing: normal;
		color: #B6B6B6!important;
		padding: 0px 0px 0px  20px;
	}
	::v-deep .md-list-item .md-inset .md-list-item-content .md-list-item-text:hover{
		color: white!important;

	}
	::v-deep .md-list-item {
		width: 100%;
	}
	.navigation-toolbar {

		.md-icon {
			cursor: pointer;
			color: #ef476f;
		}

		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

		.app-logo {
			height: 35px;
			margin: 0 auto 0 24px;
		}

		.md-title {
			color: #FFF;
			font-weight: 600;
		}
	}

	.menu-links:hover .md-icon {
		color:white;
	}

	.app-logo:hover {
		cursor: pointer;
	}

	.md-toolbar {
		background-color:#212121!important;;
	}

	.menu-links:hover .md-list-item-text {
		color: white;
	}

	::v-deep .search .md-input {
		-webkit-text-fill-color: white !important;
	}

	.search .v-label {
		color: red !important;
		opacity: 1;
	}

	.md-form input::placeholder {
		color: red !important;
		-webkit-text-fill-color: red !important;
	}

	h2 {
		font-size: xx-large;
		margin-bottom: 5px;
	}

	.md-tabs {
		margin: 30px;
	}

	.md-card.md-primary.md-theme-default.md-with-hover {
		height: 250px;
	}

	.md-card.md-primary {
		color: #ffffff !important;
	}

	::v-deep .md-toolbar .md-field {
		margin: 0;
		padding: 0;
	}

	::v-deep .md-field.md-theme-default > .md-icon:after,
	.md-field.md-theme-default:after {
		background-color: #ffffff00;
	}

	::v-deep .md-focused i.md-icon.md-icon-font.md-theme-default {
		visibility: hidden;

	}

	::v-deep .md-field .md-input-action {
		top: 0;
	}

	.result-card .md-card.md-primary.md-theme-default.md-with-hover {
		margin-bottom: 5px;
	}

	.audio-player {
		position: fixed;
		bottom: 0;
		width: 100%;
		z-index: 6;
	}
	.md-toolbar.tour-guide-ribbon {
		background: #F7F9AC 90%!important;
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
		min-height: 38px;
	}

	.tour-guide-ribbon {
		width: 100%;
		display: flex;
		min-height: 38px;
		height: 38px;
		align-items: center;
		align-content: center;
		padding: 20px;

		span {
			color: #EB4871;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 20px;
		}

		a {
			cursor: pointer;
			padding-left: 5px;
		}
	}
</style>
