import ClassService from '../../services/class.service';

const state = () => ({

    song: null,
    name: null,
    artist: null,
    startSeconds: null,
    endSeconds: null,
    playlistId: null,
    playlistDurationInterval: null,
    autoplay: true,
    isIntervalPlaying: true,
    intervals: null,
    appleintervals:null,
    playingIntervalId:null,
    songsForMix: null,
});

const getters = {

    getSong(state) {

        return state.song;
    },

    getSongName(state) {

        return state.name;
    },

    getArtist(state) {
        return state.artist;
    },

    getStartSeconds(state) {
        return state.startSeconds;
    },

    getEndSeconds(state) {
        return state.endSeconds;
    },

    getPlaylistId(state) {
        return state.playlistId;
    },

    getPlaylistDurationInterval(state) {
        return state.playlistDurationInterval;

    },

    getAutoplay(state) {
        return state.autoplay;
    },

    getIsIntervalPlaying(state) {
        return state.isIntervalPlaying;
    },
    getPlayingIntervalId(state) {
        return state.playingIntervalId;
    },
    getIntervals(state) {

        return state.intervals;
    },
    getAppleIntervals(state) {
        return state.appleIntervals;
    },
    getSongsForMix(state) {
        return state.songsForMix;

    },
};

const mutations = {

    setSong(state, data) {

        state.song = data.url;
    },

    setSongName(state, data) {

        state.name = data.name;
    },

    setArtist(state, data) {

        state.artist = data.artist;
    },

    setStartSeconds(state, data) {

        state.startSeconds = data.startSeconds;
    },

    setEndSeconds(state, data) {

        state.endSeconds = data.endSeconds;
    },

    setPlaylistId(state, data) {

        state.playlistId = data.playlistId;
    },

    setPlaylistDurationInterval(state, data) {

        state.playlistDurationInterval = data.playlistDurationInterval;
    },

    setAutoplay(state, data) {

        // console.log("set auto play " + data.autoplay);
        state.autoplay = data.autoplay;
    },

    setIsIntervalPlaying(state, data) {

        state.isIntervalPlaying = data.isIntervalPlaying;
    },
    setPlayingIntervalId(state, data) {

        state.playingIntervalId = data;
    },
    setIntervals(state, data) {

        state.intervals = data.intervals;
    }
    ,
    setAppleIntervals(state, data) {
        state.appleIntervals = data.appleIntervals;
    },
    setSongsForMix(state, data) {
        state.songsForMix = data.songsForMix;
    },
}

const actions = {

    updateSongToPlayFromClass(vuexContext, data) {

        vuexContext.commit('setSongName', data);
        ClassService.getClassMix(data.id)
                .then(res => {

                    vuexContext.commit('setSong', res);
                })
                .catch((error) => {

                    console.log('error:  ' + error);
                    throw error;
                });
    },

    updateSongToPlayFromBluePrint(vuexContext, data){

        vuexContext.commit('setSongName', data);
        vuexContext.commit('setSong', data);
        vuexContext.commit('setArtist', data);
    },

    setSongAndNameToNull(vuexContext, data){

        vuexContext.commit('setSongName', data);
        vuexContext.commit('setSong', data);
    },

    updateIntervalToPlayWithStartSeconds(vuexContext, data){

        vuexContext.commit('setSongName', data);
        vuexContext.commit('setSong', data);
        vuexContext.commit('setArtist', data);
        vuexContext.commit('setStartSeconds', data);
        vuexContext.commit('setEndSeconds', data);
    },

    updateStartSeconds(vuexContext, data)  {

        vuexContext.commit('setStartSeconds', data);
    },
    updatePlayingIntervalId(vuexContext, data)  {

        vuexContext.commit('setPlayingIntervalId', data);
    },

    updatePlayerForIntervals(vuexContext, data){

        vuexContext.commit('setSongName', data);
        vuexContext.commit('setSong', data);
        vuexContext.commit('setArtist', data);
        vuexContext.commit('setStartSeconds', data);
        vuexContext.commit('setEndSeconds', data);

        vuexContext.commit('setPlaylistId', data);
        vuexContext.commit('setPlaylistDurationInterval', data);
        vuexContext.commit('setAutoplay', data);
        vuexContext.commit('setIsIntervalPlaying', data)
        vuexContext.commit('setIntervals', data)
        vuexContext.commit('setAppleIntervals', data)
        vuexContext.commit('setPlayingIntervalId', data.playingIntervalId)
        vuexContext.commit('setSongsForMix', data)
        
    },

    resetPlayerData(vuexContext) {

        let data = {
            url: null,
            name: null,
            artist: null,
            startSeconds: null,
            endSeconds: null,
            playlistId: null,
            playlistDurationInterval: null,
            autoplay: false,
            isIntervalPlaying: true,
            appleIntervals:null,
            playingIntervalId:null,
            songsForMix : null
        };

        vuexContext.commit('setSongName', data);
        vuexContext.commit('setSong', data);
        vuexContext.commit('setArtist', data);
        vuexContext.commit('setStartSeconds', data);
        vuexContext.commit('setEndSeconds', data);

        vuexContext.commit('setPlaylistId', data);
        vuexContext.commit('setPlaylistDurationInterval', data);
        vuexContext.commit('setAutoplay', data);
        vuexContext.commit('setIsIntervalPlaying', data)
        vuexContext.commit('setAppleIntervals', data)
        vuexContext.commit('setPlayingIntervalId', data.playingIntervalId)

    },

    updateAutoPlay(vuexContext, data){

        vuexContext.commit('setAutoplay', data);
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}