import $axios from '../plugins/axios'
import $store from '../store'
class PlaylistsService {

    getPlaylistsForBluePrint(id) {

        return $axios.get(`/templates/${id}/playlists`)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    archivePlaylist(data) {

        return $axios.post(`/playlists/archives`, data)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    unArchivePlaylist(id) {

        return $axios.delete(`/playlists/archives/${id}`)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    getAllArchivedPlaylists(templateId) {
        return $axios.get(`/playlists/archives/${templateId}`)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    getAllDeletedPlaylists(templateId) {
        return $axios.get(`/playlists/deleted/${templateId}`)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    restorePlaylist(data) {

        return $axios.put(`playlists/${data.id}/recover`)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    createPlaylist(data) {

        return $axios.post(`/playlists`, data)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }


    updatePlaylist(data) {

        return $axios.put(`/playlists/${data.id}`, data)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    deletePlaylist(id) {

        return $axios.delete(`/playlists/${id}`)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    getSinglePlaylist(id) {

        return $axios.get(`/playlists/${id}`)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    getSinglePlaylistJobs(id) {

        return $axios.get(`/playlists/${id}/jobs`)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    getPlaylistMix(id) {

        return $axios.get(`/playlists/${id}/mix`)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    getPlaylistMixView(id) {
        let musicServiceId = $store.getters['auth/getCurrentUser'].current_music_streaming_service ;
        return $axios.get(`/playlists/${id}/appleMix?music_service_id=${musicServiceId}`)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    getIntervalsByPlaylist(id) {
         let musicServiceId = $store.getters['auth/getCurrentUser'].current_music_streaming_service ;
        return $axios.get(`/playlists/${id}/intervals?music_service_id=${musicServiceId}`)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    generateMix({data, id}) {

        return $axios.put(`/playlists/${id}`, data)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    cancelGenerateMix(id) {

        return $axios.put(`/playlists/${id}`,  { cancel_generate_mix: true })
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    saveSummary(data, id) {

        return $axios.put(`/playlists/${id}`, data)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }
}

export default new PlaylistsService();
