<template>

    <form class="reset-form">
        <md-field :disabled="userRole > currentUser.role_id" class="input">
            <label class="label figtree-normal-white-12px">First Name</label>
            <md-input  style="width:100%;" class="figtree-normal-white-16px-2"
                    v-model="currentUser.first_name" 
                    required
                    :disabled="userRole > currentUser.role_id"
                   >
            </md-input>
        </md-field>

        <md-field :disabled="userRole > currentUser.role_id" class="input">
            <label class="label figtree-normal-white-12px">Last Name</label>
            <md-input  style="width:100%;" class="figtree-normal-white-16px-2"
                    v-model="currentUser.last_name" 
                    required
                    :disabled="userRole > currentUser.role_id"
                   >
            </md-input>
        </md-field>

        <md-field :disabled="userRole > currentUser.role_id" class="input">
            <label class="label figtree-normal-white-12px">Email</label>
            <md-input  style="width:100%;" class="figtree-normal-white-16px-2"
                    v-model="currentUser.email" 
                    required
                    :disabled="userRole > currentUser.role_id"
                   >
            </md-input>
        </md-field>
        <md-field v-if="userRole < 4 && (userRole < currentUser.role_id )"  class="input">
            <label class="label figtree-normal-white-12px">Assign to role</label>
            <md-select required v-model="currentUser.role_id" name="role" id="role" :disabled="currentUser.role_id === 2">
                <md-option  v-for="(role, index) in roles" :key="index" :value="role.id">{{role.name}}
                </md-option>
            </md-select>
        </md-field>

        <md-field class="input">
            <label class="label figtree-normal-white-12px">Choose Facility </label>
            <md-select required v-model="currentUser.store_id" name="store" id="store" :disabled="currentUser.role_id > 3 || currentUser.role_id === 2">
                <md-option  v-for="(store, index) in accountStores" :key="index" :value="store.id">
                    {{store.name}}
                </md-option>
            </md-select>
        </md-field>
        <div >
            <md-checkbox    :disabled="currentUser.role_id > 3" 
                v-model="currentUser.active" :sync="true" />
            <span class="figtree-normal-white-18px" style="vertical-align: super">
                Active
            </span>
        </div>
       

        <md-dialog-actions v-if="isDialog">
            <md-button class="dialog__btn dialog__btn--danger"
                       @click="closeDialog()">CANCEL
            </md-button>

            <md-button class="md-primary dialog__btn md-raised md-theme-light"
                       id="done-button"
                       :disabled="$v.currentUser.$invalid"
                       @click="editUser()">DONE
            </md-button>
        </md-dialog-actions>

        <md-dialog-actions v-if="!isDialog">
            <md-button class="md-transparent dialog__btn md-raised md-theme-light"
                       id="go-back-button"
                       @click="$router.go(-1)">GO BACK</md-button>

            <md-button class="md-primary dialog__btn md-raised md-theme-light"
                       :disabled="$v.currentUser.$invalid"
                       id="update-button"
                       @click="editUser()">UPDATE</md-button>
        </md-dialog-actions>
    </form>

</template>

<script>
    import {required, email} from 'vuelidate/lib/validators';
    import RoleService from "../../services/role.service";
    import AccountService from "../../services/account.service";
    import {mapGetters} from "vuex";

    export default {
        name: 'UserForm',
        props: {
            showDialog: {
                type: Boolean,
                default: false
            },
            userProp: {
                type: Object,
                default: null
            },
            isDialog: {
                type: Boolean,
                default: false
            },
        },
        data: () => ({
            currentUser: {
                id: null,
                uuid: null,
                active: null,
                created_at: null,
                email: null,
                first_name: null,
                is_setup: null,
                last_name: null,
                role_id: null,
                store_id: null,
                account_id: null,
            },
            accountStores: [],
            roles: []
        }),
        validations: {
            currentUser: {
                first_name: {required},
                last_name: {required},
                role_id: {required},
                email: {required, email},
                store_id: {required}
            }
        },

        methods: {
            closeDialog() {

                this.$emit('closeDialog');
            },
            editUser() {

                this.$emit('updateCurrentUser', this.currentUser);
                this.closeDialog();
            },

            setCurrentUser() {

                this.currentUser = this.userProp;
            },
            getRoles(){
                if (this.userRole < 4) {

                    return RoleService.getAll()
                        .then(data => {
    
                            this.roles = data;
                        })
                        .catch((error) => {
    
                            console.log(error)
                        })
                }
            },
            getAccountStores() {

                return AccountService.getAccountStores(this.userProp.account_id)
                    .then((data) => {

                        this.accountStores = data;
                    })
                    .catch(() => {

                        this.$store.dispatch('showSnackbarMessage', {message: 'Failed. Please, try again.', duration: 8000, mode: 'fail'});
                    });
            },

            async fetchData() {

                await this.getRoles();
                await this.getAccountStores();
            },
        },
        watch: {
            //
        },
        computed: {
			...mapGetters(
				{
					userRole: 'auth/currentUserRole'
				},
			),
        },
        created() {

            const _this = this;

            _this.fetchData().then(() => {

                _this.setCurrentUser();
            });
        },

    }
</script>

<style lang="scss" scoped>
    @include dialogSettings();
    .dialog__hint {
        margin-bottom: 16px;
    }

    .active-container {
        text-align: center;

        .status-label {
            margin-top: 25px;
            margin-bottom: 20px;
            font-size: 2.4rem;
        }
    }

</style>